// =========================================
//*** Global *** 
// =========================================
*,
body {
  margin: 0 0;
  font-family: $open-sans;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // @include transition(all 1s 0s, color 0s);
}
body {
  background-color: #f2f2f2;
}
body.home {
  background-color: $white;
}

.container {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    max-width: 1200px;
  }
}

img {
  @include img-fluid;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

input {
  outline: none;
  &:hover, &:focus {
    outline: none;
  }
}


::-moz-selection { 
  color: $black;
  background: $primary-color2;
}

::selection {
  color: $black;
  background: $primary-color2;
}


// =========================================
//*** section heading style *** 
// =========================================

header#header,
header.header {
  @include media-breakpoint-up(xs) {
    position: $relative;
    margin-bottom: $margin-10 * 2;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    @include display-flex(flex, column, unset, flex-start, flex-start);
    position: $relative;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(lg) {
    margin-bottom: $margin-10 * 4;
  }
  h1 , h2 {
    @include media-breakpoint-up(xs) {
      @include heading1( 30px, 30px, 15px, 0 0 0 0);
      display: block;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include heading1( 44px, 44px, 30px, 0 0 0 0);
    }
  }
  p {
    @include media-breakpoint-up(xs) {
      line-height: normal;
      margin-bottom: $margin-10 * 0;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      margin-bottom: $margin-10 * 2;
      @include make-col(8);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(7);
    }
    br {
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        display: none;
      }
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {
        display: inline-block;
      }
      display: none;

    }
  }
 
}

// =========================================
//*** inner page's banner image  *** 
// =========================================

.inner-banner {
  position: $relative;
  img {
    width: 100%;
  }
  .caption {
    @include media-breakpoint-up(xs) {
      height: 100%;
      @include make-col-ready();
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
    @include media-breakpoint-up(xl) {}
    h1 {
      @include media-breakpoint-up(xs) {
        font-size: $small-font-size * 1.5;
        font-weight: $font-weight-700;
        line-height: $small-font-size * 1.5;
        text-transform: $capitalize-case;
        position: $relative;
        margin-bottom: 0px;
      }
      @include media-breakpoint-up(sm) {
        font-size: $small-font-size * 2;
      }
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        font-size: 52px;
        line-height: 52px;
      }
      @include media-breakpoint-up(xl) {}
      span {
        display: block;
        font-weight: $font-weight-700;
        font-size: $small-font-size * 5;
        line-height: $small-font-size * 5;
        text-transform: $upper-case;
        letter-spacing: 0;
        position: relative;
        right: -20px;
        padding: 0 11px;
      }
    }
  }
}

// =========================================
//*** Section common space *** 
// =========================================

.about-us, 
.event,
.sebscribe-box, 
.provided-srvs,
.gallery, 
.product, 
.contact-area,
#progress-row .progress-box,
.blog-row, 
.single-blog, 
// .service-row, 
.event-page,
.team-sec, 
.contact-section,
.shop-wraper,
.service-row2 {
  @include media-breakpoint-up(xs) {
    padding-top: $padding-10 * 5;
    padding-bottom: $padding-10 * 5;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    padding-top: $padding-10 * 7;
    padding-bottom: $padding-10 * 7;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    padding-top: $padding-10 * 10;
    padding-bottom: $padding-10 * 10;
  }
}

.service-row {
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    margin-top: $padding-10 * 7;
    margin-bottom: $padding-10 * 7;
    padding: 40px 50px;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    margin-top: $padding-10 * 10;
    margin-bottom: $padding-10 * 10;
    padding: 65px 100px;
  }
  margin-top: $padding-10 * 5;
  margin-bottom: $padding-10 * 5;
  background-color: white;
  padding: 30px;

}


// =========================================
//*** Horizontal social nav style *** 
// =========================================

.social {
  z-index: 0;
  @include transition(z-index 1s 0s, color 0s);
  ul {
    @include display-flex(flex, row, nowrap, left, center);
    width: 100%;
    padding: $padding-10 0;
    li {
      @include display-flex(flex, unset, unset, unset, unset);
      margin-right: $margin-10 * 3;
      a {
        padding: 0;
        color: $base-color11;
        font-size: 22px; 
        &:hover {
          color: $primary-color2;
          border-color: $primary-color2;
        }
      }
    }
  }
}

// social 2

.social2 {
  z-index: 0;
  @include transition(z-index 1s 0s, color 0s);
  ul {
    @include display-flex(flex, row, nowrap, left, center);
    width: 100%;
    li {
      @include display-flex(flex, unset, unset, unset, unset);
      margin-right: $margin-10 * 1.6;
      a {
        border: 1px solid #b6b6b6;
        border-radius: 50%;
        padding: $padding-10 * .6 $padding-10;
        color: #7b7b7b !important;
        font-size: 22px; 
        &:hover, &:focus {
          border-color: $primary-color2;
          color: $primary-color2 !important;
        }
      }
    }
  }
}

// =========================================
//*** Contact details *** 
// =========================================
.contacts {
  > ul {
    padding-bottom: $padding-10 * 4;
    margin-bottom: $padding-10 * 4;
    position: $relative;
    li {
      @include media-breakpoint-up(xs) {
        @include display-flex(flex, column, nowrap, flex-start, left);
        margin-bottom: $margin-10 * 3;
      }
      @include media-breakpoint-up(sm) {
        @include display-flex(flex, row, nowrap, flex-start, left);
        margin-bottom: $margin-10 * 3;
      }
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      span {
        @include media-breakpoint-up(xs) {
          display: inline-block;
          width: auto;
          margin-right: $margin-10 * 1.8;
          font-size: $small-font-size + 2;
          text-transform: $upper-case;
          font-weight: $font-weight-600;
          position: $relative;
          margin-bottom: $margin-10;
        }
        @include media-breakpoint-up(sm) {
          width: 100px;
          margin-bottom: 0;
        }
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
        
        &:after {
          @include media-breakpoint-up(xs) {
            @include position ($absolute, 0, auto, 0, auto);
            content: ":";
            margin: auto;
          }
          @include media-breakpoint-up(sm) {
            @include position ($absolute, 0, 0, 0, auto);
            content: ":";
            margin: auto;
          }
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {}
          @include media-breakpoint-up(xl) {}
        }
      }
      a, address {
        color: $nav-txt-color;
        font-size: $peragraph-font-size + 2;
        font-weight: $font-weight-600;
        line-height: normal;
        font-style: normal;
      }
      address {
        font-size: $peragraph-font-size;
      }
    }
  }
}

// =========================================
//*** contacts | contact-info2s *** 
// =========================================
.contacts.contact-info2 {
  > ul {
    padding-bottom: 0px;
    position: $relative;
    li {
      @include media-breakpoint-up(xs) {
        @include display-flex(flex, column, nowrap, flex-start, left);
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      
      a, address {
        @include media-breakpoint-up(xs) {
          color: $nav-txt-color;
          font-family: $roboto;
          font-size: $peragraph-font-size - 2;
          font-weight: $font-weight-400;
          line-height:  normal;
          font-style: normal;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          font-size: $peragraph-font-size + 2;
        }
        @include media-breakpoint-up(xl) {}
      }
    }
  }
  strong {
    @include media-breakpoint-up(xs) {
      display: inline-block;
      width: 100%;
      font-size: $peragraph-font-size + 2;
      text-transform: $capitalize-case;
      font-weight: $font-weight-700;
      margin-bottom: $margin-10 * 2;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      font-size: $peragraph-font-size + 4;
      margin-bottom: $margin-10 * 3;
    }
    @include media-breakpoint-up(xl) {}
  }
  p {
    font-size:  $peragraph-font-size - 1;
  }
}

.modal-dialog {

}

// slick slider dots.
.slick-dots li {
  margin: 2px;
}

.slick-dots li button:before {
  font-size: $small-font-size !important;
}

// pagiation
.pagi {
  display: flex;
  width: 150px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
  border-radius: 30px;
  padding: 10px 20px;
  li {
    display: inline-block;
    width: auto;
    float: left;
    padding: 0 8px;
    a {
      font-family: $Montserrat;
      font-size: 16px;
      font-weight: $font-weight-300;
      color: #a6a6a6;
      &:hover, &:focus {
        color: #353535;
      }
    }
  }
  .active {
    a {
      color: #353535;
    }
  }
  .pre, .nxt {
    a {
      font-weight: $font-weight-100;
    }
  }
}

// pagiation 
.pagi2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  a {
    font-size: 22px;
    line-height: 22px;
    padding: 12px 18px;
    border: 1px solid #ddd;
    color: #999;
  }
  .pre {
    border-right: none;
  }
}