// =========================================
//*** banner
// =========================================
.inner-banner.shop-banner {
  @include media-breakpoint-up(xs) {
    background-image: none;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
    min-height: 160px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    min-height: 350px;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    background-image: url(#{$img-path}/banner-shop.jpg);
    min-height: 400px;
  }
  img {
    @include media-breakpoint-up(xs) {
      display: block;
      position: $absolute;
      height: 100%;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  .container {
    @include position ($absolute, 0, 0, 0, 0);
    margin: auto;
    .caption {
      @include media-breakpoint-up(xs) {
        @include display-flex(flex, unset, nowrap, unset, center);
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      h1 {
        @include media-breakpoint-up(xs) {
          color: #8e8c8f;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
    }
  }
}

// =========================================
//*** breadcrumb
// =========================================

.breadcrumb1 {
  @include media-breakpoint-up(xs) {
    @include display-flex(flex, row, wrap, flex-start, center);
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: $margin-10 * 3;
    padding-bottom: $padding-10 * 2;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    margin-bottom: $margin-10 * 5;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: $margin-10 * 7;
  }
  li {
    @include media-breakpoint-up(xs) {
      text-transform: $capitalize-case;
      font-size: $peragraph-font-size - 2;
      font-weight: $font-weight-600;
      color: $primary-color2;
      padding-right: $padding-10 + 2;
      margin-right: $margin-10 - 3;
      position: $relative;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      font-size: $peragraph-font-size;
    }
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    a {
      @include media-breakpoint-up(xs) {
        text-transform: $capitalize-case;
        font-size: $peragraph-font-size - 2;
        font-weight: $font-weight-400;
        color:$nav-txt-color;
      }
      @include media-breakpoint-up(sm) {
        font-size: $peragraph-font-size;
      }
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        font-size: $peragraph-font-size + 2;
      }
      @include media-breakpoint-up(xl) {}
      
      &:hover {
        color: $btn-bg;
      }
    }
    &:after {
      @include media-breakpoint-up(xs) {
        @include position(absolute, -2px, 0, 0, auto);
        content: "/";
        font-size: $peragraph-font-size + 4;
        font-weight: $font-weight-300;
        color: $base-color4;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        font-weight: $font-weight-500;
        top: 0px;
      }
      @include media-breakpoint-up(xl) {}

    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

// =========================================
//*** Event-wraper / Classes list
// =========================================

// event-wraper
.shop-wraper {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
}
.shop-wraper-box {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    @include make-row();
    @include display-flex(flex, row, nowrap, unset, unset);
  }
  @include media-breakpoint-up(xl) {
    @include make-row();
  }
}
//event-area
.shop-area {
  @include media-breakpoint-up(xs) {
    margin-bottom:  $margin-10 * 4;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    
    margin-bottom:  $margin-10 * 6;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    @include make-col-ready();
    margin-bottom:  $margin-10 * 0;
  }
  @include media-breakpoint-up(xl) {
    @include make-col(9);
  }
  h2 {
    @include media-breakpoint-up(xs) {
      font-size: $peragraph-font-size * 2;
      line-height: $peragraph-font-size * 2;
      color: $base-color7;
      margin-bottom: $margin-10 * 3;
      font-weight: $font-weight-500;
      text-transform: $upper-case;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      font-size: $peragraph-font-size * 3.1;
      line-height: $peragraph-font-size * 3.1;
    }
    @include media-breakpoint-up(xl) {}
  }
  
}

// top-bar

.top-bar {
  @include media-breakpoint-up(xs) {
    @include display-flex(flex, column, wrap, flex-start, flex-start);
    margin-bottom: $margin-10 * 3;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    @include display-flex(flex, row, wrap, flex-start, flex-start);
  }
  @include media-breakpoint-up(xl) {}
  .short-bar {
    @include media-breakpoint-up(xs) {
      @include display-flex(flex, row, wrap, flex-start, center);
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }
    @include media-breakpoint-up(xl) {}
  }
  .styled-select select {
    @include media-breakpoint-up(xs) {}
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      width: 330px;
    }
    @include media-breakpoint-up(xl) {}
  }
}
.input-select {
  @include border-radius(50px);
  border: 1px solid #c8c8c8;
  padding: $padding-10;
}

// =========================================
//*** pagination
// =========================================

.pagi {
  @include media-breakpoint-up(xs) {
    
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
  @include media-breakpoint-up(xl) {}
  ul {
    @include display-flex(flex, row, nowrap, space-evenly, center);
    position: $relative;
    li {
      @include media-breakpoint-up(xs) {
        position: $relative;
        z-index: 1;
        left: 40px;
        
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      a {
        @include display-flex(flex, column, nowrap, center, flex-end);
        @include border-radius(50px);
        background-color: #5f5f5f;
        color: $white;
        width: 80px;
        height: 40px;
        padding-right: 25px;
        
      }
    }
    li.active {
      @include media-breakpoint-up(xs) {
        position: $relative;
        z-index: 3;
        left: 80px;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      a {
        background-color: $btn-bg;
        @include align-item(center);
        padding: 0;
      }
    }
    li.next {
      @include media-breakpoint-up(xs) {
        position: static;
        z-index: 0;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      a {
        background-color: transparent;
        border: 1px solid #5f5f5f;
        padding-right: 20px;
      }
    }
  }
}


.item-wraper {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    @include make-row();
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  .shop-item {
    @include media-breakpoint-up(xs) {
      margin-bottom: $margin-10 * 3;
      text-align: center;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      @include make-col(6);
      @include make-col-ready();
      margin-bottom: $margin-10 * 4;
    }
    @include media-breakpoint-up(lg) {
     
      margin-bottom: $margin-10 * 5;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: $margin-10 * 6;
      @include make-col(4);
    }
    figure {
      @include media-breakpoint-up(xs) {
        background-color: $white;
        padding: 6px;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      a {
        @include media-breakpoint-up(xs) {
          display: block;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
        > img {
          @include media-breakpoint-up(xs) {
            display: block;
            width: 100%;
          }
          @include media-breakpoint-up(sm) { }
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {}
          @include media-breakpoint-up(xl) {}
        }
      }
    }
    .item-dtls {
      @include media-breakpoint-up(xs) {}
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}

      @include display-flex(flex, row, nowrap, space-between, flex-start);
      padding: 25px 0 15px 0;
      position: $relative;
      .left {
        @include display-flex(flex, column, nowrap, left, unset);
        text-align: left;
        width: 60%;
        strong, span {
          font-family: $roboto;
          font-weight: $font-weight-600;
          color: $heading-color;
          font-size: $peragraph-font-size + 2;
          margin-bottom: $margin-10; 
          font-style: normal;
        }
        span {
          margin-bottom: 0px;
          font-size: 16px;
          font-weight: $font-weight-300;
        }
      }
      .right {
        .cart-item {
          @include border-radius(50%);
          // background-color: #edefef;
          background-color: $primary-color2;
          color: $white;
          display: inline-block;
          width: auto;
          padding: 15px;
          font-size: 22px;
          line-height: 22px;
          // color: $heading-color;
          &:hover {
            background-color: $primary-color2;
            color: $white;
          }
        }
        img {
          @include position($absolute, auto, 15px, 20px, auto);
          &:nth-of-type(2){ 
            display: none;
          }
        }
      }
    }
  }
}

// select box
.short-msg {
  font-size:  $small-font-size + 1;
  display: inline-block;
  width: auto;
  padding: $padding-10;
  padding-left: $padding-10 * 0 ;
}
.styled-select {
  border: 1px solid #e1e1e1;
  @include border-radius(50px);
  overflow: hidden;
  position: $relative;
 .styled-select select { 
    width: 60%;
  } 
}

select:focus { 
  outline: none; 
}
.styled-select select {
  cursor: pointer;
  width: 100%;
  padding: $padding-10 * 1.8 $padding-10 * 2;
  background: transparent;
  border: $none;
  font-size: $peragraph-font-size - 2;
  color: $base-color7;
  font-weight: $font-weight-400;
  /*hide default down arrow in webkit */
  -webkit-appearance: none; 
  -moz-appearance: none; 
  appearance: none; 
  background: url(#{$img-path}/dd-arrow.png) no-repeat 92% center;
}
.fa-caret-down {
  @include position($absolute, 0, 15px, 0, auto );
  margin: auto;
  font-size: $peragraph-font-size + 4;
  color: #e1e1e1;
}
@-moz-document url-prefix(){
  .styled-select select { width: 110%; }
}
 select::-ms-expand { display: none; } /* hide default down arrow in IE10*/
/* hack to fall back in opera */
_:-o-prefocus, .selector {
  .styled-select { background: $none; }
  }


/****** Style Star Rating Widget *****/
// @import "font-awesome";
fieldset { margin: 0; padding: 0; }
 
.rating { 
  border: none;
  float: left;
  > input { 
    display: none; 
    &:checked {
      ~ label{
        color: $btn-bg;
        &:hover {
          color: $btn-bg; 
          ~ label {
            color: $btn-bg;
          }
        }
      }
      + label {
        &:hover {
          color: $btn-bg;
        }
      }
    }
  }
  label {
    margin: 0;
    padding: 0;
    line-height: 0px;
  }
  > label {
    color: #ddd; 
    float: right;   
    &:before { 
      margin: 4px;
      font-size: $small-font-size + 4;
      font-family: 'acro';
      display: inline-block;
      content: "\e901";
      color: #facf2a;
    }
    &:hover {
      ~input {
        &:checked {
          ~ label {
            color: #facf2a;
          }
        }
      }
    }
    &:nth-last-of-type(1) {
      ~input {
        &:checked {
          ~ label {
            color: #333;
          }
        }
      }
    }
  }
  &:not(:checked) {
    > label {
      &:hover {
        color: $btn-bg;
        ~ label { 
          color: $btn-bg;
        }
      }
    }
  }
}

