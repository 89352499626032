
// =========================================
//*** input effect *** 
// =========================================
.input,
textarea.input, textarea {
  @include media-breakpoint-up(xs) {
    font-family: $roboto;
    width: 100%;
    display: block;
    border: none;
    padding-top: $padding-10 * 2; 
    padding-bottom: $padding-10 * 2; 
    border-bottom: solid 1px #d6d6d6;;
    transition: all 0.3s cubic-bezier(.64,.09,.08,1);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 100%, $base-color7 0%);
    background-position: 100px 100px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: $base-color7;
    margin-bottom: $margin-10 * 3;
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: $margin-10 * 0;
  }
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  &:focus, &:valid {
    box-shadow: none;
    outline: none;
    background-position: 0 0;
    &::-webkit-input-placeholder {
      // color: $base-color7;
      // font-size: 11px;
      // transform: translateY(-20px);
      // visibility: visible !important;
    }
  }
  &::-webkit-input-placeholder {
    @include media-breakpoint-up(xs) {
      @include placeholder ( $nav-txt-color , $peragraph-font-size - 2);
      font-weight:  $font-weight-300;
      font-family: $roboto;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
  }
  &::-moz-placeholder {
    @include media-breakpoint-up(xs) {
      @include placeholder ( $nav-txt-color , $peragraph-font-size - 2);
      font-weight:  $font-weight-300;
      font-family: $roboto;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
  }
  &:-ms-input-placeholder {
    @include media-breakpoint-up(xs) {
      @include placeholder ( $nav-txt-color , $peragraph-font-size - 2);
      font-weight:  $font-weight-300;
      font-family: $roboto;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
  }
  &:-moz-placeholder {
    @include media-breakpoint-up(xs) {
      @include placeholder ( $nav-txt-color , $peragraph-font-size - 2);
      font-weight:  $font-weight-300;
      font-family: $roboto;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
  }
}


// =========================================
//*** Radio button style *** 
// =========================================
.mtnl-cnt {
  @include media-breakpoint-up(xs) {
    @include display-flex(flex, column, nowrap, flex-start, flex-start);
  }
  @include media-breakpoint-up(sm) {
    @include display-flex(flex, row, nowrap, flex-start, center);
  }
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}

}


.radios.radios2 {
  @include media-breakpoint-up(xs) {
    display: flex;
    flex-direction: row;
    width: auto;
  }
  @include media-breakpoint-up(sm) {
    @include display-flex(flex, row, nowrap, flex-start, center);
    width: 180px;
    margin-left: 15px;
  }
  @include media-breakpoint-up(md) {
    padding: 0 $padding-5 * 3;
    margin-left: 0px;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  label {
    font-size: $peragraph-font-size - 2;
    font-weight: $font-weight-400;
    margin-bottom: 0px !important;
  }
  > div {
    &:nth-last-of-type(1) {
      padding-left: $padding-10;
    }
  }
}
.input-replace {
  @include equal-box(14px, 14px);
  border: 1px solid #c2c2c2;
  float: left;
  margin-right: 10px;
  border-radius: 50%;
  position: relative;
  top: 0;
  cursor: pointer;

  &:after {
    @include position ($absolute, -1px, auto, auto, -1px);
    @include equal-box(14px, 14px);
    @include border-radius(50%);
    content: '';
    background: $primary-color2;
    transform: scale(0);
    @include transition(all 0.1s);
    visibility: hidden;
  }
  &.checked:after {
    transform: scale(1);
    visibility: visible;
  }
}
.checks .input-replace:after {
  // background: #e74c3c;
}
label {
  font-size: $peragraph-font-size + 2;
  font-family: $roboto;
  font-weight: $font-weight-300;
  display: inline-block;
  height: auto;
  color: $heading-color !important;
  cursor: pointer;
  margin-bottom: $margin-10 * 2;
  text-transform: $capitalize-case;
}

// =========================================
//*** subscribe box *** 
// =========================================

input::-webkit-input-placeholder, button, textarea {
  font-family: 'roboto', sans-serif;
  transition: all 0.3s ease-in-out;
}
form {
  @include media-breakpoint-up(xs) {
    @include make-col-ready();
    @include display-flex(flex, column, unset, unset, unset);
    width: 100%;
    overflow: visible;
    padding: 0;
    margin: 0;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
}
label {
  @include media-breakpoint-up(xs) {
    font-family:  $roboto;
    font-size: $small-font-size * 2.5;
    font-weight: $font-weight-100;
    color: $subscribe-txt-color;
    display: block;
    margin-bottom: $margin-10 * 2; 
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
}
input.input-subscribe,
textarea.input-subscribe {
  @include media-breakpoint-up(xs) {
    @include transition(all 1s 0s);
    font-family: $roboto;
    width: 100%;
    display: block;
    border: none;
    padding-top: $padding-10 * 2; 
    padding-bottom: $padding-10 * 2; 
    border-bottom: solid 1px rgba(196, 215, 231, .5);
    transition: all 0.3s cubic-bezier(.64,.09,.08,1);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 99%, rgba(196, 215, 231, .3) 2%);
    background-position: 100px 100px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: rgba(196, 215, 231, .5);
    margin-bottom:  $margin-10 * 3;
    border-radius: 0;
  }
  @include media-breakpoint-up(sm) {
    margin-bottom:  $margin-10 * 0;
  }
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  
  &:focus, &:valid {
    box-shadow: none;
    outline: none;
    background-position: 0 0;
    &::-webkit-input-placeholder {
      color:#C4D7E7;
      font-size: 11px;
      transform: translateY(-20px);
      visibility: visible !important;
    }
  }
  &::-webkit-input-placeholder {
    @include media-breakpoint-up(xs) {
      @include placeholder ( #C4D7E7, $peragraph-font-size - 2);
      font-weight:  $font-weight-400;
      font-family: $roboto;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include placeholder ( #C4D7E7, $peragraph-font-size - 2);
    }
    @include media-breakpoint-up(xl) {}
  }
  &::-moz-placeholder {
    @include media-breakpoint-up(xs) {
      @include placeholder ( #C4D7E7, $peragraph-font-size - 2);
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include placeholder ( #C4D7E7, $peragraph-font-size - 2);
    }
    @include media-breakpoint-up(xl) {}
  }
  &:-ms-input-placeholder {
    @include media-breakpoint-up(xs) {
      @include placeholder ( #C4D7E7, $peragraph-font-size - 2) ;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include placeholder ( #C4D7E7, $peragraph-font-size - 2);
    }
    @include media-breakpoint-up(xl) {}
  }
  &:-moz-placeholder {
    @include media-breakpoint-up(xs) {
      @include placeholder ( #C4D7E7, $peragraph-font-size - 2);
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include placeholder ( #C4D7E7, $peragraph-font-size - 2);
    }
    @include media-breakpoint-up(xl) {}
  }
}
.btn-submit {
  @include media-breakpoint-up(xs) {
    @include border-radius(30px);
    @include position(static, auto, 0, 0, auto);
    @include align-self ( center );
  }
  @include media-breakpoint-up(sm) {
    @include position($absolute, 0, 0, 0, auto);
  }
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  &:hover, &:focus {
    outline: none;
  }
}

// =========================================
//*** Noin now modal *** 
// =========================================
.join-modal {
  @include media-breakpoint-up(xs) {
    box-shadow: 0px 55px 84px rgba(10,10,10,0.27);
    border-radius: 0;
    border: none;
    padding: $padding-10 * 3;
    text-align: center;
    border-radius: 30px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    text-align: left;
    padding:  $padding-10 * 6;
  }
  @include media-breakpoint-up(xl) {}

  .modal-header {
    border: none;
    padding: 0px;
    .close {
      @include media-breakpoint-up(xs) {
        @include position( $absolute, 40px, 40px, auto, auto);
        color: #cacaca;
        font-weight: $font-weight-500;
        border-radius: 50%;
        border: 2px solid #cacaca;
        z-index: 9;
        opacity: 1;
        padding: 2px 7px;
      }
      @include media-breakpoint-up(sm) {
        @include position( $absolute, 50px, 50px, auto, auto);
      }
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      &:hover, &:focus {
        color: $primary-color2;
        border-color: $primary-color2;
      }
    }
  }
  .modal-wraper {
    @include media-breakpoint-up(xs) {}
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    
    .tm-dtls {
      @include media-breakpoint-up(xs) {
        width: 100%;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      
      h3 {
        @include media-breakpoint-up(xs) {
          font-size:  18px;
          line-height: 18px;
          font-weight: $font-weight-700;
          color: $nav-txt-color;
          margin-bottom: $margin-10 * 3;
          // text-transform: $upper-case;
          text-transform: $capitalize-case;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {
          margin-bottom: $margin-10 * 6;
        }
        @include media-breakpoint-up(lg) {
          font-size:  26px;
          line-height: 26px;
        }
        @include media-breakpoint-up(xl) {}
      }

      form {
        @include media-breakpoint-up(xs) {
          @include display-flex(flex, row, wrap, center, center );
        }
        @include media-breakpoint-up(sm) {
          @include display-flex(flex, row, wrap, flex-end, flex-end );
        }
        @include media-breakpoint-up(md) {
          // @include make-row();
        }
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
    }
  }
  .hlfcl {
    @include media-breakpoint-up(xs) {
      @include make-col-ready();
      margin-bottom:  $margin-10 * 2.5;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include make-col(6);
      margin-bottom:  $margin-10 * 4;
    }
    @include media-breakpoint-up(xl) {}
    .inptjn {
      @include media-breakpoint-up(xs) {
        border-top: none;
        border-right: none;
        border-bottom: 1px solid #c1c1c1;
        border-left: none;
        width: 100%;
        padding: $padding-5 * 3 0;
        font-weight:  $font-weight-400;
        font-size: $peragraph-font-size - 2;
        border-radius: 0px;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}

      &::-webkit-input-placeholder {
        @include media-breakpoint-up(xs) {
          @include placeholder ( $base-color6, $peragraph-font-size - 2);
          font-weight:  $font-weight-400;
          font-family: $roboto;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
      &::-moz-placeholder {
        @include media-breakpoint-up(xs) {
          @include placeholder ( $base-color6, $peragraph-font-size - 2);
          font-weight:  $font-weight-400;
          font-family: $roboto;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
      &:-ms-input-placeholder {
        @include media-breakpoint-up(xs) {
          @include placeholder ( $base-color6, $peragraph-font-size - 2);
          font-weight:  $font-weight-400;
          font-family: $roboto;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
      &:-moz-placeholder {
        @include media-breakpoint-up(xs) {
          @include placeholder ( $base-color6, $peragraph-font-size - 2);
          font-weight:  $font-weight-400;
          font-family: $roboto;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
    }
    &:nth-of-type(3),
    &:nth-of-type(4) {
      @include media-breakpoint-up(xs) {}
      @include media-breakpoint-up(sm) {
        margin-bottom: $margin-10 * 7;
      }
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
    }
  }
  label {
    font-size: $peragraph-font-size - 2;
    color: $heading-color;
    font-weight: $font-weight-400;
  }
  .height-box {
    @include display-flex(flex, row, wrap, space-between, center );
    label {
      margin:  0;
    }
    .box-border {
      @include media-breakpoint-up(xs) {
        border: 1px solid #ccc;
        border-radius: 50px;
        display: inline-block;
        margin-left: $margin-10;
        width: 75%;
        padding: $padding-5 * 2;
      }
      @include media-breakpoint-up(sm) {
        width: 80%;
      }
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}

      .innerbox {
        @include media-breakpoint-up(xs) {
          width: 45%;
          display: inline-block; 
          text-align: center;
        }
        @include media-breakpoint-up(sm) {
          width: 49%;
        }
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
        input {
          text-align: center;
          border: none;
          background-color: transparent;
          display: inline-block;
          width: 30px; 
          font-size: $peragraph-font-size - 2;
          &::-webkit-input-placeholder {
            @include media-breakpoint-up(xs) {
              @include placeholder ( $base-color6, 14px);
              font-weight:  $font-weight-400;
              font-family: $roboto;
            }
            @include media-breakpoint-up(sm) {}
            @include media-breakpoint-up(md) {}
            @include media-breakpoint-up(lg) {
              @include placeholder ( $base-color6, 14px);
            }
            @include media-breakpoint-up(xl) {}
          }
          &::-moz-placeholder {
            @include media-breakpoint-up(xs) {
              @include placeholder ( $base-color6, 14px);
            }
            @include media-breakpoint-up(sm) {}
            @include media-breakpoint-up(md) {}
            @include media-breakpoint-up(lg) {
              @include placeholder ( $base-color6, 14px);
            }
            @include media-breakpoint-up(xl) {}
          }
          &:-ms-input-placeholder {
            @include media-breakpoint-up(xs) {
              @include placeholder ( $base-color6, 14px) ;
            }
            @include media-breakpoint-up(sm) {}
            @include media-breakpoint-up(md) {}
            @include media-breakpoint-up(lg) {
              @include placeholder ( $base-color6, 14px);
            }
            @include media-breakpoint-up(xl) {}
          }
          &:-moz-placeholder {
            @include media-breakpoint-up(xs) {
              @include placeholder ( $base-color6, 14px);
            }
            @include media-breakpoint-up(sm) {}
            @include media-breakpoint-up(md) {}
            @include media-breakpoint-up(lg) {
              @include placeholder ( $base-color6, 14px);
            }
            @include media-breakpoint-up(xl) {}
          }
        }
        span {
          color: #ccc;
          font-size:  $peragraph-font-size - 2;
          text-transform: capitalize;
        }
        &:nth-of-type(1){
          position: $relative;
          &:after {
            position: $absolute;
            content: "";
            background-color: #ccc;
            height: 18px;
            width: 1px;
            right: -10px;
            top: 0;
            bottom: 0;
            margin: auto;
          }

        }
      }

      .innerbox2 {
        @include display-flex(flex, row, wrap, space-around, center );
        width: 100%;
        input {
          border: none;
          background-color: transparent;
          display: inline-block;
          width: 30px; 
          font-size: $peragraph-font-size - 2;
          &::-webkit-input-placeholder {
            @include media-breakpoint-up(xs) {
              @include placeholder ( $base-color6, 14px);
              font-weight:  $font-weight-400;
              font-family: $roboto;
            }
            @include media-breakpoint-up(sm) {}
            @include media-breakpoint-up(md) {}
            @include media-breakpoint-up(lg) {
              @include placeholder ( $base-color6, 14px);
            }
            @include media-breakpoint-up(xl) {}
          }
          &::-moz-placeholder {
            @include media-breakpoint-up(xs) {
              @include placeholder ( $base-color6, 14px);
            }
            @include media-breakpoint-up(sm) {}
            @include media-breakpoint-up(md) {}
            @include media-breakpoint-up(lg) {
              @include placeholder ( $base-color6, 14px);
            }
            @include media-breakpoint-up(xl) {}
          }
          &:-ms-input-placeholder {
            @include media-breakpoint-up(xs) {
              @include placeholder ( $base-color6, 14px) ;
            }
            @include media-breakpoint-up(sm) {}
            @include media-breakpoint-up(md) {}
            @include media-breakpoint-up(lg) {
              @include placeholder ( $base-color6, 14px);
            }
            @include media-breakpoint-up(xl) {}
          }
          &:-moz-placeholder {
            @include media-breakpoint-up(xs) {
              @include placeholder ( $base-color6, 14px);
            }
            @include media-breakpoint-up(sm) {}
            @include media-breakpoint-up(md) {}
            @include media-breakpoint-up(lg) {
              @include placeholder ( $base-color6, 14px);
            }
            @include media-breakpoint-up(xl) {}
          }
        }
        span {
          color: #ccc;
          font-size:  $peragraph-font-size - 2;
          text-transform: capitalize;
        }
      }

    }
  }
  .btn-effect.btn-submit2 {
    @include media-breakpoint-up(xs) {
      margin-top: 20px;
      padding: 15px 50px;
      margin-right: 0px;
    }
    @include media-breakpoint-up(sm) {
      // margin-top: 0px;
      margin-right: 15px;
    }
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
  }
}

// =========================================
//*** input type number ( for join now )
// =========================================

.quantity {
  @include media-breakpoint-up(xs) {
    @include display-flex(flex, row, nowrap, flex-start, center);
    position: relative;
    max-width: 150px;
    width: 150px;
    margin-bottom: $margin-10 * 2;
  }
  @include media-breakpoint-up(sm) {
    border-right: 1px solid #ccc;
    margin-bottom: $margin-10 * 0;
  }
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  label {
    font-size: $peragraph-font-size - 2;
    color: $heading-color;
    margin: 0 15px 0 0 !important;
    font-weight: $font-weight-400;
  }
  input {
    position: $absolute;
    left: 25px;
    width: 90px;
    height: 34px;
    line-height: 16px;
    font-size: 16px;
    float: left;
    display: block;
    padding: 0;
    margin: 0;
    padding-left: 35px;
    // border: 1px solid #eee;
    border-radius: 50px;
    color: $base-color6;
    font-weight: $font-weight-400;
    &:focus {
      outline: 0;
    }
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
}
.quantity-nav {
  float: left;
  position: relative;
  left: 48px;
  height: 42px;
}
.quantity-button {
  position: relative;
  cursor: pointer;
  width: 50px;
  text-align: center;
  color: #c2c2c2;
  font-size: 16px;
  font-family: $roboto;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.quantity-button.quantity-up {
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
  height: 50%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.quantity-button.quantity-down {
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
  height: 50%;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

// =========================================
//*** Leave a comment form | blog-comment-box
// =========================================

textarea {
  resize: none;
}
.blog-comment-box {
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    @include make-row();
    padding: 35px;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    padding: 50px;
  }
  background-color: $white;
  padding: 20px;
  h3 {
    @include media-breakpoint-up(xs) {
      margin-bottom: $margin-10 * 3;
      font-size: $peragraph-font-size + 4;
      font-weight: $font-weight-600;
      color: $base-color7;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      @include make-col-ready();
      margin-bottom: $margin-10 * 3;
      font-size: $peragraph-font-size + 8;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: $margin-10 * 6;
      font-size: $small-font-size * 2.2;
    }
    @include media-breakpoint-up(xl) {}
  }
  form.leave-comment {
    @include media-breakpoint-up(xs) {
      display: inline-block;
      width: 100%;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      display: block;
    }
    @include media-breakpoint-up(xl) {}
    .half {
      @include media-breakpoint-up(xs) {
        display: inline-block;
        width: 100%;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        @include make-col-ready();
        display: inline-block;
        width: 50%;
        float: left;
      }
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {} 
    }
    .full {
      @include media-breakpoint-up(xs) {}
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        @include make-col-ready();
        display: inline-block;
        width: 100%;
        float: none;
      }
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {} 
    } 
    .btn-submit2 {
      @include media-breakpoint-up(xs) {
        float: right;
        margin-top:  $margin-10 * 3;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        margin-right:  $margin-10 * 1.5;
        margin-top:  $margin-10 * 0;
      }
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {} 
    } 
    .input {
      @include media-breakpoint-up(xs) {
        margin-bottom: $margin-10;
        font-size: $peragraph-font-size - 2;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        margin-bottom: $margin-10 * 3; 
      }
      @include media-breakpoint-up(xl) {} 
    }
  }
  > div {
    &:nth-last-of-type(1) {
      margin-bottom: 20px
    }
  }
  button.btn-km.btn-submit {
    padding: $padding-10 * 1.5 $padding-10 * 3.5;
    float: right;
    margin-top: 20px;
    margin-right: 15px;
  }
}


// =========================================
//*** contact-form2
// =========================================
.contact-form2 {
  @include media-breakpoint-up(xs) {
    border-radius: 30px;
    box-shadow: -22px 20px 73px rgba(110,110,110,0.22);
    background-color: #ffffff;
    padding: $padding-10 * 3;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    padding: $padding-10 * 6;
  }
  @include media-breakpoint-up(xl) {}
}

// =========================================
//*** input spinner for shop dteails page
// =========================================
.form-item {
  border: 1px solid #b9b9b9;
  border-radius: 50px;
  padding: 15px 30px;
  input[type=number] {
    width: 50px;
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    color: $base-color8;
    font-weight: $font-weight-600;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
}
.quanti-invitati-op {
  cursor: pointer;
  user-select: none;
  color: $base-color8;
}