// =========================================
//*** banner
// =========================================
.inner-banner.blog-banner {
  @include media-breakpoint-up(xs) {
    background-image: none;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
    min-height: 160px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    min-height: 280px;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    background-image: url(#{$img-path}/banner-blog.jpg);
    min-height: 400px;
  }
  img {
    @include media-breakpoint-up(xs) {
      display: block;
      position: $absolute;
      height: 100%;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  .container {
    @include position ($absolute, 0, 0, 0, 0);
      margin: auto;
      .caption {
        @include media-breakpoint-up(xs) {
          @include display-flex(flex, unset, nowrap, unset, center);
          @include position ($absolute, 0, 0, 0, 0);
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      h1 {
        @include media-breakpoint-up(xs) {
          color: #5c5c5c;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
    }
  }
}


// =========================================
//*** blog-sec / blog-list
// =========================================

.blogsection {
  background-color: #f5f5f5;
}
// blog-row
.blog-row {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
}

// blog-list
.blog-list {
  @include media-breakpoint-up(xs) {
    @include make-container();
    display: inline-block;
    margin-bottom: 20px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  > li{
    @include media-breakpoint-up(xs) {
      @include make-row();
      position: $relative;
      margin-bottom: $margin-10 * 3;
      overflow: visible;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include display-flex (flex, column, nowrap, center, flex-start);
      display: -webkit-box;
      margin-bottom:  $margin-10 * 6;
    }
    @include media-breakpoint-up(xl) {}
    .blog-thumbnail {
      @include media-breakpoint-up(xs) {
        position: $relative;
        @include display-flex ( flex, column, nowrap, center, flex-start);
        display: -webkit-box;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        @include make-col(8);
      }
      @include media-breakpoint-up(xl) {}
      img {
        border: 10px solid #fff;
      }
    }
    &:nth-child(even){
      .blog-thumbnail {
        @include media-breakpoint-up(xs) {
          position: $relative;
          @include display-flex ( flex, column, nowrap, center, flex-start);
         
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          @include make-col-offset(4);
        }
        @include media-breakpoint-up(xl) {}
        // .blog-social {
        //   @include position($absolute, auto, -30px, 60px, auto);
        //   padding-left: $padding-5 * 3;
        //   padding-right: $padding-10 * 4;
        // }
      }
      .blog-details {
        @include media-breakpoint-up(xs) {}
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          @include position ($absolute, 50%, auto, auto, 0);
          transform: translateY(-50%);
        }
        @include media-breakpoint-up(xl) {}
      }
    }
    &:hover, &:focus {
      .blog-thumbnail {
        img {
          box-shadow: 0px 48px 87px rgba(146,146,146,0.54);
        }
      }
    }
  }
}

// blog-thumbnail
.blog-thumbnail {
  @include media-breakpoint-up(xs) {
    position: $relative;
    @include display-flex ( flex, column, nowrap, center, flex-start);
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    @include make-col(8);
  }
  @include media-breakpoint-up(xl) {}
 
}


// blog-details
.blog-details {
  @include media-breakpoint-up(xs) {
    overflow: hidden;
    padding: $padding-10 * 1.5;
    z-index: 0;
  }
  @include media-breakpoint-up(sm) {
    padding: $padding-10 * 3 $padding-10 * 1.5;
  }
  @include media-breakpoint-up(md) {
    padding: $padding-10 * 5 $padding-10 * 2;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(6);
    @include position ($absolute, 50%, 0, auto, auto);
    transform: translateY(-50%);
    background-color: $white;
    padding: $padding-10 * 4 $padding-10 * 7;
    // box-shadow: 5px 35px 51px rgba(170, 170, 170, 0.2);
    
  }
  @include media-breakpoint-up(xl) {
    padding: $padding-10 * 7;
  }
  h3 {
    @include media-breakpoint-up(xs) {
      font-family: $roboto;
      font-weight: $font-weight-500;
      font-size: $peragraph-font-size + 4;
      line-height: $small-font-size * 2;
      color: $base-color7;
      margin-bottom: $margin-10 * 2;
      position: $relative;
      margin-left: $margin-10 * 0;
      padding-left: $padding-10 * 2;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      font-size: $peragraph-font-size + 8;
    }
    @include media-breakpoint-up(lg) {
      font-size: $small-font-size * 1.9;
      line-height: $small-font-size * 1.9;
      font-weight: $font-weight-500;
      margin-left: $margin-10 * -2;
      padding-left: $padding-10 * 2;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    @include media-breakpoint-up(xl) {}
    &:nth-of-type(1) {
      &:before {
        @include media-breakpoint-up(xs) {
          content: "";
          background-color: $primary-color2;
          @include position(absolute,0, auto, 0, 0px);
          @include equal-box(2px, 100%);
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          @include position(absolute,0, auto, 0, 0px);
        }
        @include media-breakpoint-up(xl) {}
      }
    }
  }
  .adc {
    @include media-breakpoint-up(xs) {
      display: block;
      margin-bottom: $margin-10 * 2;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      margin-bottom: $margin-10 * 4;
    }
    span {
      @include media-breakpoint-up(xs) {
        font-family: $roboto;
        display: inline-block;
        font-size: $small-font-size;
        text-transform: capitalize;
        padding-left: $padding-10 * 2;
        margin-left: $margin-10;
        position: $relative;
        cursor: pointer;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      &:before {
        @include media-breakpoint-up(xs) {
          content: "/";
          @include position(absolute,0, auto, 0, 0);
          @include equal-box (20px, auto);
          font-size: $peragraph-font-size + 2;
          line-height: $peragraph-font-size + 4;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
      &:hover, &:focus {
        color: $primary-color2;
        &:before {
          color: $primary-color2;
        }
      }
    }
    span.author {
      padding-left: $padding-10 * 0;
      margin-left: $margin-10 * 0;
      &:before {
        display: $none;
      }
    }
  }

  p {
    @include media-breakpoint-up(xs) {
      font-size: $peragraph-font-size - 2;
      line-height: $peragraph-font-size + 8;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      font-weight: $font-weight-400;
      margin-bottom: $margin-10 * 3;
    }
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      margin-bottom: $margin-10 * 6;
    }
  }

  .blog-social {
    @include media-breakpoint-up(xs) {
      @include display-flex(flex, row, nowrap, flex-end, center);
      // @include position($absolute, auto, auto, 60px, -30px);
      // box-shadow: -2px 23px 20px rgba(164, 161, 154, 0.2);
      // background-color: rgba(255, 255, 255, .9);
      background-color: transparent;
      padding: 0;
      margin-top: 25px; 
      text-align: center;
      width: 100%;
      // padding-left: $padding-10 * 4;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      margin-top: 15px; 
    }
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    li {
      @include media-breakpoint-up(xs) {
        margin: 0 3px;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      a {
        @include media-breakpoint-up(xs) {
          @include border-radius (50%);
          @include equal-box (25px, 25px);
          display: inline-block;
          color: #868686;
          font-size: 12px;
          border: 1px solid #868686;
          padding: 4px;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
        &:hover, &:focus {
          @include media-breakpoint-up(xs) {
            color: $primary-color2;
            border: 1px solid $primary-color2;
          }
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {}
          @include media-breakpoint-up(xl) {}
        }
      }
    }
  }
}

.center-align {
  text-align: center;
}