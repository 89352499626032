/*Template Name: Acrobat Total Fitness
Author: 0effortthemes 
Version: 1.0.1
Primary use:general
Tags: responsive, html5 template, landing, business, application, corporate, bootstrap4
*/

/* ------------------------------------------ */
/* TABLE OF CONTENTS
/* ------------------------------------------ */
/* body */
/* 01 - Color Variables -> Defines all color variables used in this theme */
/* 02 - Fonts -> Defines all text fonts and icon fonts family */

/* 03 - Header 
+ Logo
+ Navigation Menu
+ Search
*/

/* 04 - Banner
+ Home page banner
+ Inner pages banner
*/

/* 05 - Home */
/* 06 - About */
/* 07 - Services */
/* 08 - Gallery */
/* 09 - Events */
/* 10 - Shop */
/* 11 - Shop Details */
/* 12 - Coaches */
/* 13 - Blog  */
/* 14 - Single Blog  */
/* 15 - contact Us  */

/* 16 - Content Area -> Defines global content area style */
/* 17 - Footer */

// main sass file *** @include here all the sass files.
// *** bootstrap *** 
@import "./vendor/bootstrap";

// *** Helpers ***
@import "./helpers/variables";
@import "./helpers/mixins";
@import "./helpers/fonts";



// bower:scss
@import "bower_components/slick-carousel/slick/slick-theme.scss";
// endbower

// *** Base *** 
@import "./base/reset";
@import "./base/typography";

// *** vendor *** 
@import "./vendor/owl-carousel";
@import "./vendor/isotope";
@import "./vendor/slick";
@import "./vendor/slick-theme";

// *** Themes *** 
@import "./themes/theme";

// ***  Components *** 
@import "./components/buttons";
@import "./components/navigation";

// *** Layout ***
@import "./layout/header";
@import "./layout/footer";
@import "./layout/sidebar";
@import "./layout/form";

// *** Pages ***
@import "./pages/home";
@import "./pages/about";
@import "./pages/services";
@import "./pages/gallery";
@import "./pages/event";
@import "./pages/shop";
@import "./pages/shop_details";
@import "./pages/coaches";
@import "./pages/blog";
@import "./pages/single_blog";
@import "./pages/contact-us";

