// ====================================
// google font link 
// ====================================

// Open Sans
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
$open-sans: 'Open Sans', sans-serif;

// Roboto
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
$roboto: 'Roboto', sans-serif;

// Montserrat
@import url('https://fonts.googleapis.com/css?family=Montserrat:500,700,800');
$Montserrat: 'Montserrat', sans-serif;

// ====================================
// acro SVG icons
// ====================================

@font-face {
  font-family: 'acro';
  src:  url('#{$icomoon-font-path}/acro.eot?o8be09');
  src:  url('#{$icomoon-font-path}/acro.eot?o8be09#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/acro.ttf?o8be09') format('truetype'),
    url('#{$icomoon-font-path}/acro.woff?o8be09') format('woff'),
    url('#{$icomoon-font-path}/acro.svg?o8be09#acro') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="acro-"], [class*=" acro-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'acro' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function unicode($str) {
  @return unquote("\"") + $str + unquote("\"");
}


$acro-half-star-shape: "\e900";
$acro-star: "\e901";
$acro-behance-logo: "\e902";
$acro-dribbble-logo: "\e903";
$acro-facebook-logo: "\e904";
$acro-left-arrow: "\e905";
$acro-online-shopping-cart: "\e906";
$acro-right-arrow: "\e907";
$acro-twitter: "\e908";
$acro-view: "\e909";
$acro-vimeo-logo: "\e90a";
$acro-right-arrow2: "\e90b";
$acro-envelope: "\e90c";
$acro-phone-receiver: "\e90d";
$acro-handicap: "\e90e";
$acro-mortarboard: "\e90f";
$acro-chat-comment-oval-speech-bubble-with-text-lines: "\e910";
$acro-like: "\e911";
$acro-reply-arrow: "\e912";
$acro-share: "\e913";




.acro-half-star-shape {
  &:before {
    content: $acro-half-star-shape; 
  }
}
.acro-star {
  &:before {
    content: $acro-star; 
  }
}
.acro-behance-logo {
  &:before {
    content: $acro-behance-logo; 
  }
}
.acro-dribbble-logo {
  &:before {
    content: $acro-dribbble-logo; 
  }
}
.acro-facebook-logo {
  &:before {
    content: $acro-facebook-logo; 
  }
}
.acro-left-arrow {
  &:before {
    content: $acro-left-arrow; 
  }
}
.acro-online-shopping-cart {
  &:before {
    content: $acro-online-shopping-cart; 
  }
}
.acro-right-arrow {
  &:before {
    content: $acro-right-arrow; 
  }
}
.acro-twitter {
  &:before {
    content: $acro-twitter; 
  }
}
.acro-view {
  &:before {
    content: $acro-view; 
  }
}
.acro-vimeo-logo {
  &:before {
    content: $acro-vimeo-logo; 
  }
}
.acro-right-arrow2 {
  &:before {
    content: $acro-right-arrow2; 
  }
}
.acro-envelope {
  &:before {
    content: $acro-envelope; 
  }
}
.acro-phone-receiver {
  &:before {
    content: $acro-phone-receiver; 
  }
}
.acro-handicap {
  &:before {
    content: $acro-handicap; 
  }
}
.acro-mortarboard {
  &:before {
    content: $acro-mortarboard; 
  }
}
.acro-chat-comment-oval-speech-bubble-with-text-lines {
  &:before {
    content: $acro-chat-comment-oval-speech-bubble-with-text-lines; 
  }
}
.acro-like {
  &:before {
    content: $acro-like;     
    color: #d75a4a;
  }
}
.acro-reply-arrow {
  &:before {
    content: $acro-reply-arrow; 
  }
}
.acro-share {
  &:before {
    content: $acro-share; 
  }
}
