// =========================================
//*** banner
// =========================================
.inner-banner.event-banner {
  @include media-breakpoint-up(xs) {
    background-image: none;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
    min-height: 160px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    min-height: 350px;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    background-image: url(#{$img-path}/banner-event.jpg);
    min-height: 400px;
  }

  img {
    @include media-breakpoint-up(xs) {
      display: block;
      position: $absolute;
      height: 100%;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  .container {
    @include position ($absolute, 0, 0, 0, 0);
    margin: auto;
    .caption {
      @include media-breakpoint-up(xs) {
        @include display-flex(flex, unset, nowrap, unset, center);
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      h1 {
        @include media-breakpoint-up(xs) {
          color: #535353;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
    }
  }
}

// =========================================
//*** Event-wraper / Classes list
// =========================================
// event-wraper
.event #page {
  overflow: hidden;
}

.event-wraper2 {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    @include make-row();
  }
  @include media-breakpoint-up(xl) {}
  .sidebar {
    @include media-breakpoint-up(xs) {}
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      @include make-col-offset(1);
    }
  }
}
  
//event-area
.event-area2 {
  @include media-breakpoint-up(xs) {
    margin-bottom:  $margin-10 * 4;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    @include make-col-ready();
    margin-bottom:  $margin-10 * 6;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    margin-bottom:  $margin-10 * 0;
  }
  @include media-breakpoint-up(xl) {
    @include make-col(8);
    
  }
}


// single-event box
.single-event2 {
  @include media-breakpoint-up(xs) {
    margin-bottom:  $margin-10 * 3;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    margin-bottom:  $margin-10 * 4;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom:  $margin-10 * 5;
    @include make-row();
  }
  @include media-breakpoint-up(xl) {
    margin-bottom:  $margin-10 * 6;
  }
  &:nth-last-of-type(1){
    margin-bottom: 0px;
  }
  .events-thumb {
    @include media-breakpoint-up(xs) {
      position: $relative;
      // @include display-flex (flex, unset, unset, unset, unset );
      margin-bottom: $margin-10 * 2;
    }
    @include media-breakpoint-up(sm) {
      position: $relative;
      // @include display-flex (flex, row-reverse, unset, flex-end, unset );
      margin-bottom: $margin-10 * 4.5;
    }
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include make-col-ready();
      @include display-flex(flex, row-reverse, nowrap, flex-end, center);
    }
    @include media-breakpoint-up(xl) {
      @include display-flex(flex, row-reverse, nowrap, flex-start, center);
    }
    img {
      border: 11px solid $white;
    }
    .events-social {
      @include media-breakpoint-up(xs) {}
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
    }
    &:hover {
      .events-social {
        @include media-breakpoint-up(xs) {
          background-color: $primary-color;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
    }
  }
  
  .events-dtls {
    @include media-breakpoint-up(xs) {}
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include make-col-ready();
    }
    @include media-breakpoint-up(xl) {}
    h3{
      @include media-breakpoint-up(xs) {
        font-size: $peragraph-font-size + 8;
        line-height: $peragraph-font-size + 8;
        font-weight: $font-weight-600;
        color: $heading-color;
        margin-bottom: $margin-10;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        margin-bottom: $margin-10 * 1.8;
        font-size: $small-font-size * 3;
        line-height: $small-font-size * 3;
        font-weight: $font-weight-700;
      }
      @include media-breakpoint-up(xl) {}
    }
    .post-nd {
      @include media-breakpoint-up(xs) {
        display: block;
        margin-bottom: $margin-10 * 2.5;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        margin-bottom: $margin-10 * 3.5;
      }
      @include media-breakpoint-up(xl) {}
      .clock  {
        display: inline-block;
        width: 16px;
        float: left;
        height: 16px;
        position: relative;
        top: 3px;
        margin-right: 10px;
      }
      span {
        font-size: $peragraph-font-size - 2;
        line-height: $peragraph-font-size;
        font-weight: $font-weight-300;
        color: $base-color7;
        font-style: italic;
        font-weight: $font-weight-400;
        padding-right: 1px;
        position: $relative;
        &:after {
          content: ",";
        }
        &:nth-of-type(1),
        &:nth-last-of-type(1) {
          &:after {
            content: ",";
            display: none;
          }
        }
      }
    }
    p {
      @include media-breakpoint-up(xs) {
        margin-bottom: $margin-10 * 2;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        margin-bottom: $margin-10 * 3;
      }
      @include media-breakpoint-up(xl) {}    
    }
  }
  p {
    @include media-breakpoint-up(xs) {}
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      line-height: 28px;
      margin-bottom: $margin-10 * 4;
    }
    @include media-breakpoint-up(xl) {}
  }
}


//  .events-social
.events-social2 {
  @include media-breakpoint-up(xs) {
    @include display-flex(flex, row, nowrap, flex-start, center);
    overflow: hidden;
    background-color: $transparent;
    margin-top:  $margin-10 * 2;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    @include display-flex(flex, row, nowrap, space-around, center);
    @include position ($absolute, 0, 0, auto, auto);
    margin-top:  $margin-10 * 0;
  }
  @include media-breakpoint-up(xl) {}
  li {
    @include media-breakpoint-up(xs) {
      margin-left: $margin-10 * 0;
      margin-right: $margin-10 * 1.5;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      margin-left: $margin-10 * 1.5;
      margin-right: $margin-10 * 0;
    }
    @include media-breakpoint-up(xl) {}
    a {
      @include media-breakpoint-up(xs) {
        font-size: $peragraph-font-size;
        color: #a4a4a4;
        display: block;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        padding: $padding-5;
      }
      @include media-breakpoint-up(xl) {}
      &:hover, &:focus {
        color: $primary-color2;
      }
    }
  }
}