// =========================================
//*** footer *** 
// =========================================

footer {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  background: $base-color3;
  background-size: cover;
  section {
    @include media-breakpoint-up(xs) {
      @include make-row();
      padding-top: $padding-10 * 5;
      padding-bottom: $padding-10 * 5;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      padding-top: $padding-10 * 7;
      padding-bottom: $padding-10 * 7;
    }
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      padding-top: $padding-10 * 10;
      padding-bottom: $padding-10 * 10;
    }
    .footer-col {
      @include media-breakpoint-up(xs) {
        @include make-col-ready();
        margin-bottom: $margin-10 * 3;
      }
      @include media-breakpoint-up(sm) {
        @include make-col-ready();
      }
      @include media-breakpoint-up(md) {
        @include make-col-ready();
      }
      @include media-breakpoint-up(lg) {
        @include make-col-ready();
        margin-bottom: $margin-10 * 0;
        border-right: 1px solid rgba(255, 255, 255, 0.3);
      }
      @include media-breakpoint-up(xl) {
        @include make-col-ready();
      }
      &:nth-of-type(1) {
        @include media-breakpoint-up(xs) {}
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {
          padding-right:  $padding-10 * 3;
          width: 30%;
        }
        @include media-breakpoint-up(lg) {
          padding-right:  $padding-10 * 3;
          width: 20%;
        }
        @include media-breakpoint-up(xl) {
          padding-right:  $padding-10 * 8;
        }
      }
      &:nth-of-type(2) {
        @include media-breakpoint-up(xs) {}
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {
          width: 70%;
          padding-left:  $padding-10 * 3;
          padding-right:  $padding-10 * 3;
        }
        @include media-breakpoint-up(lg) {
          width: 40%;
          padding-left:  $padding-10 * 3;
          padding-right:  $padding-10 * 3;
        }
        @include media-breakpoint-up(xl) {
          padding-left:  $padding-10 * 8;
          padding-right:  $padding-10 * 8;
        }
      }
      &:nth-of-type(3) {
        @include media-breakpoint-up(xs) {}
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          padding-left:  $padding-10 * 3;
          width: 40%;
          border-right: none;
        }
        @include media-breakpoint-up(xl) {
          padding-left:  $padding-10 * 8;
        }
      }
    }
  }
}

.footer-col {
  .header {
    @include media-breakpoint-up(xs) {
      margin-bottom: $margin-10 * 3;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      margin-bottom: $margin-10 * 6;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: $margin-10 * 6;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: $margin-10 * 6;
    }
    h4, h5 {
      @include media-breakpoint-up(xs) {
        color: $white;
        font-size: $peragraph-font-size + 2;
        font-weight: $font-weight-700;
        text-transform: $capitalize-case;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
    }
  }
  .ftr-abt {
    @include media-breakpoint-up(xs) {
      padding: 0;
      margin-bottom: $margin-10 * 2;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      margin-bottom: $margin-10 * 2.5;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: $margin-10 * 4;
    }
    @include media-breakpoint-up(xl) {}
    p {
      color: #4e5b66;
      font-weight: $font-weight-400;
    }
  }
  .social {
    @include media-breakpoint-up(xs) {
      margin-bottom: $margin-10 * 2;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      margin-bottom: $margin-10 * 2.5;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: $margin-10 * 4;
    }
    @include media-breakpoint-up(xl) {}
  }
  .sebscribe-box {
    form {
      @include align-item (left);
      input.input-subscribe1 {
        color: #4e5b66;
        font-size: 13px;
        &::-webkit-input-placeholder {
          @include placeholder ($btn-bg1, 13px);
          color: #4e5b66;
          font-size: 13px;
          font-weight: $font-weight-400;
        }
      }
      button,
      .btn-effect {
        @include media-breakpoint-up(xs) {
          @include border-radius(30px);
          @include position($absolute, 15px, 15px, auto, auto);
          @include align-self ( center );
          @include transition(background-color 1s 0s, color 1s);
          font-family: $roboto;
          font-weight: $font-weight-400;
          text-align: center;
          margin: auto 0;
          border: none;
          background-color: $btn-bg1;
          color: #4e5b66;
          cursor: pointer;
          padding: 6px;
          height: 34px;
          width: 120px;
          text-transform: $upper-case;
          font-size: $small-font-size + 1;
          padding: 7px 0px;
        }
        @include media-breakpoint-up(sm) { }
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        &:hover, &:focus {
          outline: none;
          color: $btn-text1-hover;
          @include transition(background-color 1s 0s, color 2s);
        }
      }
      // footer social
      .social {
        z-index: 0;
        @include transition(z-index 1s 0s, color 0s);
        ul {
          @include display-flex(flex, row, nowrap, left, center);
          width: 100%;
          padding: $padding-10 0;
          li {
            @include display-flex(flex, unset, unset, unset, unset);
            margin-right: $margin-10 * 3;
            a {
              padding: 0;
              color: $btn-bg1;
              font-size: 22px; 
              &:hover {
                color: #ef719c;
              }
            }
          }
        }
      }
    }
  }
  &:nth-last-of-type(1){
    @include media-breakpoint-up(xs) {
      margin: 0;
    }
  }

//footer Nav
.ftr-nav {
  li {
    @include media-breakpoint-up(xs) {
      margin-bottom: $margin-10 * 1.5;
      padding-left: $padding-10 * 2.3;
      position: $relative;
      overflow: hidden;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      margin-bottom: $margin-10 * 2;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: $margin-10 * 2.5;
    }
    @include media-breakpoint-up(xl) {}
    &:before {
      @include transition(all 1s 0s);
      @include equal-box (6px, 6px);
      @include border-radius(50px);
      @include position ($absolute, 0, 0, 0, 0);
      content: "";
      background-color: #a2a2a2;
      margin: auto 0;
    }
    &:hover, &:focus {
      &:before {
        background-color: $primary-color2;
      }
    }
    a {
      @include media-breakpoint-up(xs) {
        font-size: $peragraph-font-size - 2;
        line-height: $peragraph-font-size - 2;;
        color: #a2a2a2;
        font-weight: $font-weight-600;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
    }
  }
}
  
}// end footer col
.tweets {
  ul{
    li{
      @include media-breakpoint-up(xs) {
        margin-bottom: $margin-10 * 1;
        border-bottom: 1px solid rgba(255, 255, 255, .2);
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        margin-bottom: $margin-10 * 2;
        position: $relative;
      }
      @include media-breakpoint-up(xl) {
        margin-bottom: $margin-10 * 2;
      }
      a {
        @include media-breakpoint-up(xs) {
          font-weight: $font-weight-600;
          font-size: $peragraph-font-size -2;
          color: #a2a2a2;
          position: $relative;
          display: inline-block;
          overflow: hidden;
          margin-bottom: $margin-10 * 1.5;
          padding-left: $padding-10 * 3;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          padding-left: $padding-10 * 4;
        }
        @include media-breakpoint-up(xl) {}
        span {
          color: $white;
          font-family: $roboto;
          font-size: 13px;
          font-style: italic;
        }
      }
      .acro-twitter {
        @include media-breakpoint-up(xs) {
          @include position( $absolute !important, 5px , auto, auto ,0);
          color: $base-color11;
          font-size: 20px;
          margin-right: 10px;
          position: $relative;
          bottom: 10px; 
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          @include position( $absolute, 5px , auto, auto ,0);
        }
        @include media-breakpoint-up(xl) {}
      }
      &:hover {
        a, p {
          color: #a2a2a2;
        }
      }
      &:last-child {
        margin-bottom: 0;
        border: none;
        a {
          margin-bottom: 0;
        }
      }
    }// li end
  }

  p {
    @include media-breakpoint-up(xs) {
      color: #a6aaab;
      font-weight: $font-weight-400;
      margin-bottom: 5px;
      font-size: 14px;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
  }
}

// map 

#map-canvas {
  width: 100%;
  height: 300px;
  margin: 0px;
  padding: 0px;
  .gmnoprint, button, img[src^='https://maps.gstatic.com/mapfiles/api-3/images/google_white5.png'] { 
    display: none !important;
    opacity: 0;
  }
}

.copyright-sec {
  @include media-breakpoint-up(xs) {
    background-color: #1d2b3a;
    padding-top: $padding-5 * 6;
    padding-bottom: $padding-5 * 6;
    margin-left: 0;
    margin-right: 0;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  .cpy-row {
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      @include display-flex(flex, row, nowrap, space-between, center);
    }
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}

    @include make-col-ready();
    @include display-flex(flex, column, nowrap, space-between, center);
    padding: 0;
    margin: 0;
    .footer-logo {
      display: inline-block;
      a {
        display: block;
        color: #6a7f8a;
        font-size: 12px;
      }
    }
    .cr-txt {
      ul {
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {
          @include display-flex (flex, row, nowrap, flex-end, center);
        }
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
        @include display-flex (flex, row, nowrap, flex-end, center);
        
        li {
          @include media-breakpoint-up(xs) {
            padding: 0 $padding-10;
          }
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {
            padding: 0 $padding-10 * 2.4;
          }
          @include media-breakpoint-up(xl) {}
          
          a {
            display: inline-block;
            color: #6a7f8a;
            font-size: $small-font-size + 1;
          }
          &:first-child {
            @include media-breakpoint-up(sm) {}
            @include media-breakpoint-up(md) {
              line-height: 16px;
            }
            @include media-breakpoint-up(lg) {}
            @include media-breakpoint-up(xl) {}
            border-right: 1px solid #6a7f8a;
            line-height: 14px;
          }
          &:hover {
            a {
              color: $primary-color2;
            }
          }
        }
      }
     
      
    }
  }
}


