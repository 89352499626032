// *** nav menu ***
.navbar {
  @include media-breakpoint-up(xs) {
    background-color: rgba(0, 0, 0, 0);
    @include make-row();
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    padding-top: $padding-10 * 0;
    padding-bottom: 0;
  }
  .navbar-toggler{
    @include media-breakpoint-up(xs) {
      position: $relative;
      z-index: 9;
      padding-left: 0px;
      padding-right: 0px;
      &:focus{
        outline: 0;
      }
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
  } //end navbar-toggler
  .navbar-brand {
    @include media-breakpoint-up(xs) {
      margin: 0 !important;
      max-width: 50px;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      margin: $margin-auto;
      text-align: $text-align-center;
      max-width: 80px;
    }
    @include media-breakpoint-up(lg) {
      @include position(absolute, 0, 0, 0, 0);
      max-width: 100%;
    }
    @include media-breakpoint-up(xl) {
      @include position(absolute, 0, 0, 0, 0);
      max-width: 100%;
    }
    img {
      @include media-breakpoint-up(xs) {
        z-index: 999;
        position: $absolute;
        top: 10px;
        right: 0px;
        left: 0px;
        margin: auto;
        max-width: 55px;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        max-width: 70px;
      }
      @include media-breakpoint-up(lg) {
        top: 0px;
        right: 0px;
        max-width: 60px;
        margin-top: $margin-10 * 2;
      }
      @include media-breakpoint-up(xl) {
        top: 0px;
        right: 0px;
        max-width: 70px;
        margin-top: $margin-10 * 2;
      }
    }
    &:before {
      @include media-breakpoint-up(xs) {
        @include position(absolute, 30px, 0, 0, 0px);
        @include border-radius(50%);
        @include equal-box(110px, 110px);
        content: "";
        background-color: $white;
        margin: $margin-auto;
        z-index: 999;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        @include position(absolute, 50px, 0, 0, 0);
        @include equal-box(150px, 150px);
      }
      @include media-breakpoint-up(lg) {
        @include position(absolute, 55px, 0, 0, 0);
        @include equal-box(120px, 120px);
        display: block;
      }
      @include media-breakpoint-up(xl) {
        @include position(absolute, 60px, 0, 0, 0);
        @include equal-box(150px, 150px);
      
      }
    }
  } //end navbar-brand 

  .navbar-collapse {
    @include media-breakpoint-up(xs) {
      position: $absolute;
      left: 0;
      right: 0;
      z-index: 99;
      top: 70px;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      position: static;
      padding-top: 0px;
    }
    .navbar-nav {
      @include media-breakpoint-up(xs) {
        background-color: rgba(0, 0, 0, .92);
        padding-top: $padding-10 * 4;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        padding-top: $padding-10 * 7;
        padding-bottom: 0px;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        }
      @include media-breakpoint-up(lg) {
        padding-top: 0px;
        background-color: transparent;
        @include align-item(center);
      }
      .nav-item {
        @include media-breakpoint-up(xs) {
          border-bottom: 1px solid rgba(255, 255, 255, .1);
          text-align: $text-align-center;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {
          margin-right: $margin-10 * 0;
          @include last(4) {}
        }
        @include media-breakpoint-up(lg) {
          margin-right: $margin-10 * 2;
          border-bottom: none;
          @include last(4) { margin-right: $margin-10 * 1.5;}
        }
        @include media-breakpoint-up(xl) {
          margin-right: $margin-10 * 3.6;
          @include last(4) { margin-right: $margin-10 * 2.8;}
        }
        &:nth-child(5) {
          @include media-breakpoint-up(xs) {}
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {
            margin-right: $margin-10 * 19.5;
          }
          @include media-breakpoint-up(xl) {
            margin-right: $margin-10 * 29;
          }
        }
        .nav-link {
          @include media-breakpoint-up(xs) {
            color: $white;
            text-transform: $upper-case;
            font-size: $peragraph-font-size - 1px;
            line-height: normal;
            font-weight: $font-weight-400;
            padding: $padding-5 * 2 0 !important;
            @include transition(all .5s ease 0s);
          }
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {
            color: $nav-txt-color;
            padding: $padding-10 * 2.5 0 !important;
          }
          @include media-breakpoint-up(xl) {
            padding: $padding-10 * 2.5 0 !important;
          }
        } // end nav-link

        &:hover, &:focus {
          .nav-link {
            @include media-breakpoint-up(xs) {
              background-color: $nav-txt-color-hover;
              color: $white;
              font-weight: $font-weight-600;
            }
            @include media-breakpoint-up(md) {
              font-weight: $font-weight-600;
            }
            @include media-breakpoint-up(lg) {
              background-color: transparent;
              color: #090809;
            }
            @include media-breakpoint-up(xl) {}
          }
        }
        &:last-child {
          border: none;
          margin-right: 0;
        }
        //join-now-item 
        .nav-link.join-now.btn-effect {
          padding: $padding-10 $padding-10 * 3 !important;
        }
      } //nav-item
      .nav-item.active {
        .nav-link {
          @include media-breakpoint-up(xs) {
            color: $white;
            font-weight: $font-weight-600;
          }
          @include media-breakpoint-up(md) {
            color: $white;
            font-weight: $font-weight-600;
          }
          @include media-breakpoint-up(lg) {
            background-color: transparent;
            color: #090809;
          }
          @include media-breakpoint-up(xl) {}
        }
      }
    } //end navbar-nav
  } // end navbar-collapse
}


//=================================================
// Nav icon efect
//=================================================

#nav-icon2 {
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 50%;
  background: $primary-color2;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
  left:0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
  top: 12px;
}

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
  top: 24px;
}

#nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 4px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 4px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 4px;
  top: 20px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 4px);
  top: 20px;
}



// dropdown-menu 
.dropdown-menu {
  @include media-breakpoint-up(xs) {
    border-radius: 0;
    border: none;
    background-color: rgba(255, 255, 255, .8);
    padding: 0px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    top: 74px;
  }
  @include media-breakpoint-up(xl) {}
  a {
    @include media-breakpoint-up(xs) {
      padding: $padding-10 $padding-5 * 3;
      font-size: $peragraph-font-size - 1;
      text-transform: capitalize;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    &:hover, &:focus  {
      background-color: $primary-color2;
      color: $white;
    }
  }
}
