// =========================================
//*** Global heading style *** 
// =========================================
h1, h2, h3,h4, h5, h6 {
  font-family: $Montserrat;
  line-height: normal;
  color: $heading-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// =========================================
//*** Paragraph  *** 
// =========================================
p {
  @include media-breakpoint-up(xs) {
    font-family: $roboto;
    font-size: $peragraph-font-size - 2;
    color: #373737;
    font-weight: $font-weight-400;
    line-height: 20px;
    margin-bottom: $margin-10 * 2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    font-size: $peragraph-font-size;
    line-height: 22px;
  }
  @include media-breakpoint-up(xl) {}
}

// =========================================
//*** Anchor  *** 
// =========================================
a {
  text-decoration: $text-decoration-none;
  &:hover, &:focus {
    outline: none;
  }
}