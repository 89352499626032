$ms: -ms-;
$moz: -moz-;
$webkit: -webkit-;

// =========================================
//*** Position *** 
// =========================================

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// =========================================
//*** nav nth-child calculation from last *** 
// =========================================

@mixin last($num) {
  &:nth-last-child(-n + #{$num}) {
   @content;
 }
}

// =========================================
//*** Equal box *** 
// =========================================

@mixin equal-box( $width: 0, $height: 0){
  width: $width;
  height: $height;
}

// =========================================
//*** h1 Style *** 
// =========================================

@mixin heading1($h1-font-size:$h1-heading, $h1-line-height:$small-font-size * 5, $h1-margin:40px, $h1-padding: 0px){
  font-size: $h1-font-size;
  margin-bottom: $h1-margin;
  font-weight: $font-weight-700;
  line-height: $h1-line-height; 
  padding: $h1-padding;
  text-transform: $capitalize-case;
}
@mixin heading1-span() {
  font-size: $peragraph-font-size;
  font-weight: $font-weight-500;
  letter-spacing: 3px;
  display:  $display-block;
  line-height: normal;
  font-family: $roboto;
}

// =========================================
//*** flex-box *** 
// =========================================

@mixin align-item($align-item) {
  align-items: $align-item;
  #{$moz}align-items: $align-item;
  #{$webkit}align-items: $align-item;
}
@mixin align-self($align-self) {
  align-self: $align-self;
  #{$moz}align-self: $align-self;
  #{$webkit}align-self: $align-self;
}

@mixin justify-content ($justify-content) {
  justify-content: $justify-content;
  #{$moz}justify-content: $justify-content;
  #{$webkit}justify-content: $justify-content;
}

@mixin display-flex( $display, $flex-direction, $flex-wrap, $justify-content, $align-item) {
  display: $display;
  display: #{$moz}$display;
  display: #{$webkit}$display;

  flex-direction: $flex-direction;
  #{$moz}flex-direction: $flex-direction;
  #{$webkit}flex-direction: $flex-direction;

  flex-wrap: $flex-wrap;
  #{$moz}flex-wrap: $flex-wrap;
  #{$webkit}flex-wrap: $flex-wrap;

  justify-content: $justify-content;
  #{$moz}justify-content: $justify-content;
  #{$webkit}justify-content: $justify-content;

  align-items: $align-item;
  #{$moz}align-items: $align-item;
  #{$webkit}align-items: $align-item;
}

@mixin border-radius($radius) {
  border-radius: $radius $radius $radius $radius;
  #{$ms}border-radius: $radius $radius $radius $radius;
  #{$moz}border-radius: $radius $radius $radius $radius;
  #{$webkit}border-radius: $radius $radius $radius $radius;
}

// =========================================
//*** transition*** 
// =========================================

@mixin transition($transition...) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}
@mixin transition-property($property...) {
  -moz-transition-property:    $property;
  -o-transition-property:      $property;
  -webkit-transition-property: $property;
  transition-property:         $property;
}
@mixin transition-duration($duration...) {
  -moz-transition-property:    $duration;
  -o-transition-property:      $duration;
  -webkit-transition-property: $duration;
  transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function:    $timing;
  -o-transition-timing-function:      $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
  -moz-transition-delay:    $delay;
  -o-transition-delay:      $delay;
  -webkit-transition-delay: $delay;
  transition-delay:         $delay;
}

// =========================================
//*** transform *** 
// =========================================

@mixin transform( $translate) {
  transform: translateX($translate);
  #{$ms}transform: translateX($translate);
  #{$moz}transform: translateX($translate);
  #{$webkit}transform: translateX($translate);
}


// =========================================
//*** box-shadow *** 
// =========================================

@mixin box-shadow($top, $left, $blur, $radius, $color, $inset:"") {
  #{webkit}box-shadow:$top $left $blur $color #{$inset};
  #{$moz}box-shadow:$top $left $blur $color #{$inset};
  #{$ms}box-shadow:$top $left $blur $color #{$inset};
  box-shadow:$top $left $blur $color #{$inset};
}

// =========================================
//*** placeholder *** 
// =========================================

@mixin placeholder( $inputColor, $inputFont-size) {
  color: $inputColor;
  font-size: $inputFont-size;
}

