// =========================================
//*** banner
// =========================================
.inner-banner.shop-dtls-banner {
  @include media-breakpoint-up(xs) {
    background-image: none;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
    min-height: 160px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    min-height: 350px;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    background-image: url(#{$img-path}/banner-shop2.jpg);
    min-height: 400px;
  }
  img {
    @include media-breakpoint-up(xs) {
      display: block;
      position: $absolute;
      height: 100%;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  .container {
    @include position ($absolute, 0, 0, 0, 0);
    margin: auto;
    .caption {
      @include media-breakpoint-up(xs) {
        @include display-flex(flex, unset, nowrap, unset, center);
        @include position ($absolute, 0, 0, 0, 0);
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      h1 {
        @include media-breakpoint-up(xs) {
          color: #e0e5f5;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
    }
  }
}



// =========================================
//*** Shop Details Wraper
// =========================================

.shop-dtls-wraper {
  @include media-breakpoint-up(xs) {
    margin-bottom: $margin-10 * 4;
    margin-top: $margin-10 * 4;
  }
  @include media-breakpoint-up(sm) {
    // margin-bottom: $margin-10 * 6;
    margin-top: $margin-10 * 6;
  }
  @include media-breakpoint-up(md) {
    // margin-bottom: $margin-10 * 6;
    margin-top: $margin-10 * 8;
  }
  @include media-breakpoint-up(lg) {
    @include make-row();
    margin-bottom: $margin-10 * 6;
    margin-top: $margin-10 * 10;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: $margin-10 * 8;
    margin-top: $margin-10 * 12;
  }
}
  
//event-area
.shop-dtls-area {
  @include media-breakpoint-up(xs) {
    margin-bottom:  $margin-10 * 4;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    @include make-col-ready();
    margin-bottom:  $margin-10 * 6;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    margin-bottom:  $margin-10 * 0;
  }
  @include media-breakpoint-up(xl) {
    @include make-col(9);
  }
}

.product-dtls-row1 {
  @include media-breakpoint-up(xs) {
    margin-bottom:  $margin-10 * 3;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    margin-bottom:  $margin-10 * 4;
  }
  @include media-breakpoint-up(lg) {
    @include make-row();
    margin-bottom:  $margin-10 * 5;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom:  $margin-10 * 6;
  }
  figure {
    @include media-breakpoint-up(xs) {
      margin-bottom: $margin-10 * 2;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      width: 45%;
      @include make-col-ready();
    }
    @include media-breakpoint-up(xl) {
      width: 45%;
    }
    img {
      border: 8px solid $white;
    }
  }
  .prdtls {
    @include media-breakpoint-up(xs) {
      @include display-flex(flex, column, wrap, unset, unset);
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      width: 55%;
      @include make-col-ready();
    }
    @include media-breakpoint-up(xl) {
      width: 55%;
    }
    h2 {
      @include media-breakpoint-up(xs) {
        color: #353535;
        text-transform: $capitalize-case;
        font-weight: $font-weight-600;
        font-size: $small-font-size * 2;
        line-height: $small-font-size * 2;
        margin-bottom: $margin-10 * 2;
        font-family: $Montserrat;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        font-size: $small-font-size * 3;
        line-height: $small-font-size * 3;
        margin-bottom: $margin-10 * 2;
      }
      @include media-breakpoint-up(xl) {}
    }
    .item-price {
      @include media-breakpoint-up(xs) {
        font-family: $Montserrat;
        color: #353535;
        font-weight: $font-weight-600;
        font-size: $small-font-size * 2;
        line-height: $small-font-size * 2;
        margin-bottom: $margin-10 * 1.5;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        font-size: $small-font-size * 3;
        line-height: $small-font-size * 3;
        margin-bottom: $margin-10 * 2;
      }
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      small {
        font-family: $Montserrat;
        font-weight: $font-weight-600;
      }
    }
    p {
      @include media-breakpoint-up(xs) {
        margin-bottom: $margin-10 * 4.5;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        margin-bottom: $margin-10 * 6;
      }
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {
        margin-bottom: $margin-10 * 8.5;
      }
      font-size: 14px;
    }
   
  }
}

.product-review {
  @include media-breakpoint-up(xs) {
    @include display-flex(flex, row, wrap, space-between, unset);
    border-bottom: 1px solid #ddd;
    padding-bottom: $padding-10 * 3.5;
    margin-bottom: $margin-10 * 2.5;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    padding-bottom: $padding-10 * 2.5;
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: $padding-10 * 3;
  }
  .review-text {
    font-size: $small-font-size;
  }
}

.qnc-box {
  @include media-breakpoint-up(xs) {
    @include display-flex(flex, row, wrap, space-between, center);
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  .item-qnt-box {
    @include media-breakpoint-up(xs) {
      @include border-radius(50px);
      display: inline-block;
      width: auto;
      padding: $padding-10 * .2 $padding-10 * 1.5;
      border: 1px solid #bcbcbc;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      padding: $padding-10 $padding-10 * 1.5;
    }
    @include media-breakpoint-up(xl) {}
    .input-search3 {
      color: $base-color7;
      font-weight: $font-weight-500;
      padding: $padding-10 $padding-10 * 1.5;
      padding-right: $padding-10 * 3.5;
      width: 70px;
      font-size: $peragraph-font-size;
      text-transform: $capitalize-case;
      -webkit-appearance: menulist;
      -moz-appearance: menulist;
      appearance: menulist;
      border: none;
      text-align: center;

      &::-webkit-input-placeholder {
        @include placeholder ($base-color8, 16px);
        font-weight: $font-weight-300;
      }
      &::-moz-placeholder {
        @include placeholder ($base-color8, 16px);
        font-weight: $font-weight-300;
      }
      &:-ms-input-placeholder {
        @include placeholder ($base-color8, 16px);
        font-weight: $font-weight-300;
      }
      &:-moz-placeholder {
        @include placeholder ($base-color8, 16px);
        font-weight: $font-weight-300;
      }
    }
  }
  a.btn-addtocart {
    padding: $padding-5 * 3 $padding-10 * 3; 
    color: $primary-color2;
    font-weight: $font-weight-600;
    &:hover, &:focus {
      @include transition(all 1s 0s, color 2s);
      color: $white;
      border-color: $primary-color2;
    }
  }
}

.related-product {
  h3 {
    @include media-breakpoint-up(xs) {
      margin-bottom: $margin-10 * 2.5;
      text-transform: $capitalize-case;
      font-size: $peragraph-font-size + 2;
      font-weight: $font-weight-600;
      color: $base-color7;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      margin-bottom: $margin-10 * 3.5;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: $margin-10 * 4.5;
    }
    @include media-breakpoint-up(xl) {}
  }
}

// =========================================
//*** vertical-tabs 
// =========================================

.vertical-tabs-container {
  @include media-breakpoint-up(xs) {
    border-top: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
    margin-bottom: $margin-10 * 3;
    overflow: hidden;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    margin-bottom: $margin-10 * 4;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    margin-bottom: $margin-10 * 5;
    
  }
  li {
    @include media-breakpoint-up(xs) {}
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    list-style: none;
  }
  .vertical-tabs {
    @include media-breakpoint-up(xs) {
      display: none;
      position: relative;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      display: inline;
      float: left;
      width: 100%;
      display: block;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    @include media-breakpoint-up(xl) {}
    &:after {
      content: "";
      width: 1px;
      height: 32px;
      background-color: #dcdcdc;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    &:focus {
      outline: none;
    }
    a.vertical-tab {
      color: #333;
      display: block;
      padding-bottom: $padding-10 * 2.5;
      padding-top: $padding-10 * 2.5;
      font-size: $peragraph-font-size + 2;
      text-transform: $capitalize-case;
      font-weight: $font-weight-400;
      line-height: 18px;
      // border-bottom: 1px solid gainsboro;
      &:nth-last-of-type(1){
        border-bottom: none;
      }
      span {
        display: inline-block;
        background-color: #5ecfb9;
        border-radius: 50%;
        padding: 3px;
        font-size: 14px;
        font-weight: $font-weight-700;
        color: $white;
        width: 24px;
        text-align: center;
        margin-left: 20px;
      }
    }
    a.vertical-tab.is-active {
      @include media-breakpoint-up(xs) {}
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        font-weight: $font-weight-600;
        // margin-right: -1px;
      }
      @include media-breakpoint-up(xl) {}
    }
  }
  a.vertical-tab-accordion-heading {
    @include media-breakpoint-up(xs) {
      background-color: white;
      color: #333;
      border-top: 1px solid gainsboro;
      cursor: pointer;
      display: block;
      font-weight: bold;
      padding: 0.75em 0.809em;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      display: none;
    }
    @include media-breakpoint-up(xl) {}
    &:focus, &:hover {
      color: $btn-bg;
    }
    &:first-child {
      border-top: none;
    }
  }
  a.vertical-tab-accordion-heading.is-active {
    background: #f6f6f6;
    border-bottom: none;
  }

  .vertical-tab-content-container {
    @include media-breakpoint-up(xs) {
      display: block;
      margin: 0 auto;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      height: auto;
      width: 100%;
      display: inline-block;
    }
    @include media-breakpoint-up(xl) {}
    a {
      &:focus {
        outline: none;
      }
    }
  }
  .vertical-tab-content {
    @include media-breakpoint-up(xs) {
      padding: $padding-10 * 1.5;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      padding: $padding-10 * 5 0; 
      padding-right: 0;
      border: none;
      display: none;
    }
    @include media-breakpoint-up(xl) {}
    h3 {
      @include media-breakpoint-up(xs) {
        font-size: $small-font-size * 2;
        margin-bottom: $margin-10 * 1.5;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
    }
    p {
      color: $heading-color;
      line-height: 28px;
      font-size: 14px;
      &:nth-last-of-type(1) {
        margin-bottom: 0px !important;
      }
    }
  }
  
  &:after {
    clear: both;
    content: "";
    display: block;
  }
}






