//====================================
// *** buttons hover effect
//====================================
$btn-width: 110px !default;
$btn-height: 30px !default;
$btn-full: $btn-height+$btn-width !default;
$btn-half: $btn-full/2 !default;
.btn-effect {
  &:before,
  &:after {
    z-index: -1;
  }
}

.btn-effect[class^="btn-"] {
  @include media-breakpoint-up(xs) {
    @include border-radius(50px);
    font-size: $small-font-size;
    font-weight: $font-weight-400;
    position: relative;
    display: inline-block;
    padding: $padding-10 $padding-10 *2;
    font-family: $roboto;
    text-transform: uppercase;
    overflow: hidden;
    border: 1px solid $primary-color2;
    z-index: 9;
    background-color: $transparent;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    font-size: $peragraph-font-size;
    padding: $padding-10 $padding-10 *3;
  }
  @include media-breakpoint-up(xl) {}

}
@keyframes criss-cross-left {
  0% {
    left: -20px;
  }
  50% {
    left: 50%;
    @include size(20px);
  }
  100% {
    left: 50%;
    @include size($btn-width*1.5);
  }
}
@keyframes criss-cross-right {
  0% {
    right: -20px;
  }
  50% {
    right: 50%;
    @include size(20px);
  }
  100% {
    right: 50%;
    @include size($btn-width*1.5);
  }
}

.btn-effect {
  position: relative;
  color: $white;
  background-color: $primary-color2;
  &:before,
  &:after {
    @include size(20px);
    position: $absolute;
    top: 50%;
    content: '';
    @include border-radius(50%);
    @include equal-box(20px, 20px);
    background: $primary-color2;
  }
  &:before {
    left: -20px;
    transform: translate(-50%, -50%);
  }
  &:after {
    right: -20px;
    transform: translate(50%, -50%);
  }
  &:hover, &:focus {
    color: $white;
    border-color: $primary-color2;
    &:before {
      animation: criss-cross-left 0.8s both;
    }
    &:after {
      animation: criss-cross-right 0.8s both;
    }
  }
}
//=================================================
// join now button
//=================================================
.join-now-item {
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    @include position($absolute, 0, 0, 0, auto);
    @include display-flex (flex, row, nowrap, unset, center);
    z-index: 999;
    padding: 0 $padding-5 * 3;  
  }
  @include media-breakpoint-up(xl) {}
}
.btn-effect.join-now {
  @include media-breakpoint-up(xs) {
    padding: 7px 15px;
    background-color: $transparent;
    color: $nav-txt-color;
    border-color: $nav-txt-color;
    font-weight: $font-weight-500;
    position: $relative;
    &:hover {
      color: $white !important;
      @include transition(all 1s 0s, color 2s);
    }
    &:after, &:before {
      background-color: $nav-txt-color;
    }
  }
  @include media-breakpoint-up(sm) {
    padding: $padding-10 $padding-10 * 3;
  }
  @include media-breakpoint-up(md) {}

  @include media-breakpoint-up(lg) {
    padding: $padding-10 $padding-10 * 0;
    border-radius: 0;
    border: none;
    &:before {
      display: none;
      animation: none;
    }
    &:after {
      @include equal-box (100%, 1px);
      @include position ($absolute, auto, 100%, 0, auto);
      @include transition(all 1s 0s);
      animation: none;
      content: "";
      background-color: $primary-color2;
      transform: translate(0, 0);
      border-radius: 0px;
    }
    &:hover {
      color: $primary-color2 !important;
      @include transition(all 1s 0s);
      &:after {
        @include position ($absolute, auto, 0, 0, auto);
      }
    }
  }
  @include media-breakpoint-up(xl) {}
}

.btn-effect.btn-km {
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  
  padding: $padding-10 * 1.7 $padding-10 * 3;
  background-color: $transparent;
  color: $primary-color2;
  border-color: $primary-color2;
  font-weight: $font-weight-600;
  &:hover {
    color: $white !important;
    @include transition(all 1s 0s, color 2s);
  }
  &:after, &:before {
    background-color: $primary-color2;
  }
}

.btn-effect.btn-white {
  @include media-breakpoint-up(sm) {
    color: $white!important;
    border-color: $white!important;
    &:hover {
      color: $base-color10 !important;
      @include transition(all 1s 0s, color 2s);
    }
    &:after, &:before {
      background-color: $white !important;
    }
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}

}

.btn-effect.btn-submit2 {
  @include media-breakpoint-up(xs) {
    padding: $padding-10 * 1.7 $padding-10 * 6;
    background-color: $transparent;
    color: $primary-color2;
    border-color: $primary-color2;
    font-weight: $font-weight-600;
    cursor: pointer;
    margin-top: $margin-10 * 4;
  }
  @include media-breakpoint-up(sm) {
    margin-top: 0;
  }
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  &:hover, &:focus {
    outline: none;
    background-color: $primary-color2;
    color: $btn-text1-hover;
  }
}

// bttton more 2
a.btn-more2 {
  @include media-breakpoint-up(xs) {
    font-size: $peragraph-font-size;
    font-weight: $font-weight-600 !important;
    padding: $padding-5 * 3 $padding-5 * 6 !important; 
    background-color: $transparent;
    color: $primary-color2;
    border-color: $primary-color2 !important;
    &:hover {
      color: $white !important;
      @include transition(all 1s 0s, color 2s);
    }
    &:after, &:before {
      background-color: $primary-color2;
    }
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
}

// load more

.load-more {
  @include transition(box-shadow 1s 0s);
  @include border-radius(50px);
  background-color: #fff;
  box-shadow: -1px 10px 18px rgba(221,221,223,0.75);
  color: #8b8b8b;
  font-size: $small-font-size * 3.2;
  line-height: $small-font-size * 2;
  padding: $padding-5 * 3 $padding-10 * 6;
  display: inline-block;
  &:hover, &:focus  {
    box-shadow: -1px 10px 18px rgba(221,221,223,0.2);
  }
}


input.input-subscribe1 {
  @include media-breakpoint-up(xs) {
    font-weight: $font-weight-400;
    width: 55%;
    display: block;
    border: none;
    padding-top: $padding-10 * 2; 
    padding-bottom: $padding-10; 
    border-bottom: solid 1px $base-color2;
    transition: all 0.3s cubic-bezier(.64,.09,.08,1);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 99%, $subscribe-txt-color 4%);
    background-position: 100px 100px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-bottom: $margin-10 * 3;
  }
  @include media-breakpoint-up(sm) {
    width: 70%;
  }
  @include media-breakpoint-up(md) {
    width:calc(100% - 150px);
  }
  @include media-breakpoint-up(lg) {}
  &:focus, &:hover {
    box-shadow: none;
    outline: none;
    background-position: 0 0;
    &::-webkit-input-placeholder {
      color: $subscribe-txt-color;
      font-size: 11px;
      transform: translateY(-20px);
      visibility: visible !important;
    }
  }
}

input.btn-submit1 {
  @include media-breakpoint-up(xs) {
    @include border-radius(30px);
    @include position($absolute, 15px, 15px, auto, auto);
    @include align-self ( center );
    font-family: $roboto;
    font-weight: $font-weight-400;
    text-align: center;
    margin: auto 0;
    border: none;
    background-color: $btn-bg1;
    color: #4e5b66;
    cursor: pointer;
    padding: 6px;
    height: 34px;
    width: 120px;
    text-transform: $upper-case;
    font-size: $small-font-size + 1;
    padding: 7px 0px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  &:hover, &:focus {
    outline: none;
    background-color: $primary-color;
    color: $btn-text1-hover;
  }
}
