// =========================================
//*** banner
// =========================================
.inner-banner.banner-gallery {
  @include media-breakpoint-up(xs) {
    background-image: none;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
    min-height: 160px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    min-height: 350px;
  }
  @include media-breakpoint-up(lg) {
    
  }
  @include media-breakpoint-up(xl) {
    background-image: url(#{$img-path}/banner-contact.jpg);
    min-height: 400px;
  }
  img {
    @include media-breakpoint-up(xs) {
      display: block;
      position: $absolute;
      height: 100%;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  .container {
    @include position ($absolute, 0, 0, 0, 0);
    margin: auto;
    .caption {
      @include media-breakpoint-up(xs) {
        @include display-flex(flex, unset, nowrap, unset, center);
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      h1 {
        @include media-breakpoint-up(xs) {
          color: #535353;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
    }
  }
}

// =========================================
//*** gallery 
// =========================================

.gallery-box {
  @include media-breakpoint-up(xs) {
    margin-bottom: 30px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    margin-bottom: 50px;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    margin-bottom: 70px;
  }
  
  .thumbnail {
    position: relative;
    .col-media-item {
      display: block !important;
      width: auto;
      padding: 2px 1px;
      position: relative;
      overflow: hidden !important;
    }
    .gal-cap {
      @include transition(all 1s 0s, color 0s);
      @include position($absolute, 0, 0, 0, 0);
      @include display-flex(flex, row, nowrap, space-between, flex-end);
      padding: $padding-10 * 5 $padding-10 * 4;
      background-color: rgba(238, 92, 141, 0);
      opacity: 0;
      
      strong {
        font-family: $roboto;
        font-size: $peragraph-font-size + 2;
        font-weight: $font-weight-300;
        color: $white;
        padding: $padding-5 $padding-10;
        border-left: 2px solid $white;
      }
      span {
        font-family: $roboto;
        font-size: $peragraph-font-size + 2;
        font-weight: $font-weight-300;
        color: $white;
      }
    }
    &:hover {
      .gal-cap {
        background-color: rgba(238, 92, 141, .85);
        opacity: 1;
      }
    }
  }
}
.col-media-item {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  .overlay.left { width: 0; height: 100%; top: 0; bottom: 0; left: 0; right: 100%; }
  .overlay.right { width: 0; height: 100%; top: 0; bottom: 0; left: 100%; right: 0; }
  .overlay.top { width: 100%; height: 0; top: auto; bottom: 100%; left: 0; right: 0; }
  .overlay.bottom { width: 100%; height: 0; top: 100%; bottom: 0; left: 0; right: 0; }
  .overlay {
    position: absolute;
    background-color: rgba(66, 172, 191, .9);
    overflow: hidden;
    transition: .5s ease;
    .text {
      font-family: $Montserrat;
      color: white;
      font-size: $peragraph-font-size + 2;
      line-height: 24px;
      position: absolute;
      bottom: 35px;
      left: 35px;
      white-space: nowrap;
      text-decoration: $capitalize-case;
      font-weight: $font-weight-600;
      // span {
      //   border-radius: 50%;
      //   height: 25px;
      //   width: 25px;
      //   display: inline-block;
      //   overflow: hidden;
      //   float: left;
      //   margin-right: 10px;
      //   img {
      //     max-width: 100%;
      //     width: auto;
      //   }
      // }
    }
  }
  &:hover, &:focus  {
    .overlay.left, 
    .overlay.right  { width: 100%; left: 0; }
    .overlay.top { height: 100%; bottom: 100%; top: 0; }
    .overlay.bottom  { height: 100%; bottom: 0; top: auto; }
  }
}
.view {
  position: $absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  img {
    max-width: 100%;
    width: auto;
  }
}


.gallery-box.gal-pgbox {
  .btn-group {
    .btn-sort {  
      background-color: #f2f2f2 !important;  
      span { 
        background-color: #f2f2f2 !important; 
      }
    }
    .btn-sort.active {
      background-color: #f2f2f2 !important;  
    }
  }
}

