// =========================================
//*** banner
// =========================================
.inner-banner.single-blog-banner {
  img {
    display: block;
  }
  .container {
    @include position ($absolute, 0, 0, 0, 0);
    margin: auto;
    .caption {
      @include media-breakpoint-up(xs) {
        @include display-flex(flex, unset, nowrap, unset, center);
        @include position ($absolute, 0, 0, 0, 0);
        padding: 0 15px;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      h1 {
        @include media-breakpoint-up(xs) {
          color: #535353;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
    }
  }
}


// =========================================
//*** Single blog page
// =========================================

//blog-wraper
.blog-wraper {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    @include make-row();
    @include justify-content(space-between);
  }
  @include media-breakpoint-up(xl) {}
}


//blog-detail-main
.blog-detail-main {
  @include media-breakpoint-up(xs) {
    margin-bottom:  $margin-10 * 4;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    @include make-col-ready();
    margin-bottom:  $margin-10 * 6;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(7);
   
    margin-bottom:  $margin-10 * 0;
  }
  @include media-breakpoint-up(xl) {
    @include make-col(8);
  }
  .blog-thumbnail {
    @include media-breakpoint-up(xs) {
      @include make-col(12);
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    img {
      border: 10px solid #fff;
    }
  }

  .blog-details {
    @include media-breakpoint-up(xs) {
      @include make-col(12);
      position: $relative;
      padding-top: $padding-10 * 3;
      margin-bottom: $margin-10 * 3;
      padding-left: 0px;
      padding-right: 0px;
      box-shadow: none;
      top: 0;
      transform: translateY(0);
      background-color: transparent; 
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      padding-top: $padding-10 * 5;
      margin-bottom: $margin-10 * 5;
    }
    @include media-breakpoint-up(xl) {}
    h3 {
      @include media-breakpoint-up(xs) {
        margin-left: 0px;
        padding-left: 0px;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        padding-left: $margin-10 * 0;
      }
      @include media-breakpoint-up(xl) {}
      &:nth-of-type(1) {
        padding-left: $padding-10 * 2;
      }
      &:before {
        width: 3px;
      }
    }
    p {
      @include media-breakpoint-up(xs) {}
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        font-size: $peragraph-font-size;
      }
      @include media-breakpoint-up(lg) {
        line-height: 26px;
      }
      @include media-breakpoint-up(xl) {}
    }
    blockquote {
      @include media-breakpoint-up(xs) {
        color: #878787;
        font-family: $roboto;
        font-size: $peragraph-font-size;
        font-weight: $font-weight-300;
        font-style: italic;
        line-height: 20px;
        position:  relative;
        margin-bottom: $margin-10 * 4;
        margin-left: $margin-10 * 2.5;
        letter-spacing: .3px;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        font-weight: $font-weight-400;
        margin-left: $margin-10 * 6;
        line-height: 26px;
      }
      @include media-breakpoint-up(xl) {}
      &:before {
        content: "";
        background-color: #ccc;
        @include position(absolute,0, auto, 0, -30px);
        @include equal-box(1px, 100%);
      }
      
    }
    
  }
}

.blog-social {
  @include media-breakpoint-up(xs) {
    @include display-flex(flex, row, nowrap, flex-end, center);
    // @include position($absolute, auto, auto, 60px, -30px);
    // box-shadow: -2px 23px 20px rgba(164, 161, 154, 0.2);
    // background-color: rgba(255, 255, 255, .9);
    background-color: transparent;
    padding: 0;
    margin-top: 25px; 
    text-align: center;
    width: 100%;
    // padding-left: $padding-10 * 4;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    margin-top: 15px; 
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  li {
    @include media-breakpoint-up(xs) {
      margin: 0 3px;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    a {
      @include media-breakpoint-up(xs) {
        @include border-radius (50%);
        @include equal-box (25px, 25px);
        display: inline-block;
        color: #868686;
        font-size: 12px;
        border: 1px solid #868686;
        padding: 4px;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      &:hover, &:focus {
        @include media-breakpoint-up(xs) {
          color: $primary-color2;
          border: 1px solid $primary-color2;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
    }
  }
}


// =========================================
//*** ul list type 1
// =========================================

.list-type1 {
  @include media-breakpoint-up(xs) {
    margin-bottom:  $margin-10 * 2.5;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    margin-bottom:  $margin-10 * 4.5;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    margin-bottom:  $margin-10 * 6.5;
  }
  li {
    @include media-breakpoint-up(xs) {
      font-family: $roboto;
      font-size: $peragraph-font-size;
      line-height: $peragraph-font-size + 2;
      color: $base-color7;
      font-weight:  $font-weight-400;
      margin-bottom:  $margin-10;
      position: $relative;
      padding-left: $padding-10 * 2;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      margin-bottom:  $margin-10 * 2;
    }
    @include media-breakpoint-up(xl) {}
    
    &:before {
      @include position(absolute, 0, auto, 0, 0);
      @include equal-box(5px, 5px);
      @include border-radius(50%);
      content: "";
      background-color: $primary-color2;
      margin: auto;
    }
    &:last-child {
      margin-bottom:  $margin-10 * 0;
    }
  }
  
}


// =========================================
//*** author-says
// =========================================

.author-says {
  @include media-breakpoint-up(xs) {
    display: inline-block;
    // @include display-flex(flex, column, nowrap, unset, unset);
    padding: $padding-10 * 3 0;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    padding: $padding-10 * 4 0;
    // @include display-flex(flex, column, nowrap, unset, unset);
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    padding: $padding-10 * 6 0;
  }
  figure {
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include equal-box (100px, auto);
      display: inline-block;
      float: left;
      display: inline-block;
      margin-bottom: $margin-10 * 1.5;
    }
    @include media-breakpoint-up(xl) {}
     img {
      @include border-radius(50%);
      border: 4px solid $white;
     }
  }
  .author-dtls {
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      padding-left: $padding-10 * 3;
    }
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    color: $base-color9;
    display: inline-block;
    width: 75%;
    strong {
      font-size: $peragraph-font-size + 4;
      font-family: $open-sans;
      color: $heading-color;
      font-weight: $font-weight-600;
      margin-bottom: $margin-10;
      display: block;
      font-style: normal;
    }
    span {
      font-size: 14px;
      font-family: $roboto;
      color: $primary-color2;
      display: inline-block;
      position: relative;
      padding-right: 10px;
      margin-right: 5px;
      &:after {
        position: absolute;
        content: "";
        background-color: $base-color9;
        height: 10px;
        width: 1px;
        top: 0px;
        bottom: 0px;
        right: 0px;
        margin: auto;
      }
      &:nth-last-of-type(1) {
        padding-right: 0px;
        margin-right: 0px;
        &:after {
          display: none;
        }
      }
      a {
        color: $base-color9;
        &:hover {
          color: $primary-color2;
        }
      }
    }
    p {
      font-size: $peragraph-font-size;
      margin-bottom: $margin-10;
    }
    .author-social {
      @include media-breakpoint-up(xs) {
        @include display-flex(flex, row, nowrap, unset, unset);
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      li {
        @include media-breakpoint-up(xs) {
          margin-right: $margin-10 * 2;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          margin-right: $margin-10 * 3;
        }
        @include media-breakpoint-up(xl) {}
        a {
          @include media-breakpoint-up(xs) {
            color: #848484;
            font-size: $small-font-size * 1.5;
          }
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {}
          @include media-breakpoint-up(xl) {}
          &:hover, &:focus {
            @include media-breakpoint-up(xs) {
              color: $primary-color;
            }
            @include media-breakpoint-up(sm) {}
            @include media-breakpoint-up(md) {}
            @include media-breakpoint-up(lg) {}
            @include media-breakpoint-up(xl) {}
          }
        }
      }
    }
  }
  li {
    // @include display-flex(flex, column, nowrap, unset, unset);
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
  }
}
.author-says.depth2 {
  padding: 10px 0;
  margin-inline-start: 100px;
  margin-bottom: 10px;
  li {
    margin-bottom: 10px;
  }
} 

.lcs-box {
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  display: flex;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  padding: 20px 0;
  ul {
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    li {
      margin: 0 5px;
      a {
        color: $txt-color1;
        padding: 5px;
        font-size: 14px;
      }
      i {
        margin-right: 3px;
        &:before {
          color: $txt-color1;
        }
      }
      &:hover {
        a, i::before {
          color: $primary-color2;
        }
      }
    }
  }
}

.rnc-box {
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  display: flex;
  ul {
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    li {
      width: auto;
      margin: 0 !important;
      a {
        color: $txt-color1;
        padding: 5px;
        font-size: 14px;
        
      }
      i {
        margin-right: 3px;
        &:before {
          color: $txt-color1;
        }
      }
      &:hover {
        a, i::before {
          color: $primary-color2;
        }
      }
    }
  }
}

