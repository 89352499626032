// main sass file *** @include here all the sass files.
.container {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    max-width: 1170px;
  }
}
// =========================================
//*** banner
// =========================================
.banner-box {
  display: block;
  .sm-banner {
    @include media-breakpoint-up(xs) {
      display: block;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
}

.banner {
  img {
    @include media-breakpoint-up(xs) {
      width: 100%;
      display: flex;
      display: -webkit-flex;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
   
  }
}
.ben-txt {
  @include media-breakpoint-up(xs) {
    text-align: right;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  }
#slider {
  @include media-breakpoint-up(xs) {
    display: none;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    padding-top:  60px !important;
  }
  @include media-breakpoint-up(xl) {
    display: block;
  }
}
.ben-txt h1 {
  @include media-breakpoint-up(xs) {
    font-family: 'Montserrat', sans-serif;
    font-size: 48px;
    line-height: 48px;
    font-weight: 600;
    margin: 20px 0 0 0;
    color: #fff;
    letter-spacing: 0px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    font-size: 120px;
    line-height: 120px;
    margin: 20px 0 0 0;
  }
  @include media-breakpoint-up(xl) {
    font-size: 220px;
    line-height: 220px;
    margin: 20px 0 0 0;
    letter-spacing: -10px; 
  }
}
.ben-txt strong {
  @include media-breakpoint-up(xs) {
    font-family: 'Montserrat', sans-serif;
    font-size: $peragraph-font-size;
    line-height: $peragraph-font-size;
    font-weight: 600;
    margin: 0px 0;
    display: inline-block;
    width: 210px;
    color: #fff;
    text-align: left;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
    line-height: 28px;
    font-weight: 600;
    margin: 0px 0;
    display: inline-block;
    width: 190px;
    color: #fff;
    text-align: left;
  }
  @include media-breakpoint-up(xl) {}
  }
.ben-txt span {
  @include media-breakpoint-up(xs) {
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
    line-height: 10px;
    font-weight: 400;
    margin: 0px 0;
    display: block;
    color: #fff;
    display: block;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    font-size: $peragraph-font-size;
    line-height: $peragraph-font-size + 4;
    font-weight: 500;
  }
  }

  /* bensub-txt */
  .bensub-txt:before {
    content: "";
    background-color: rgba(32, 29, 31, .30);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    position: absolute;
    left: -45px;
    top: -20px;
    z-index: -1;
  }
  .bensub-txt span,
  .bensub-txt1 span {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 300;
    margin: 0px 0;
    display: block;
    color: #f1f5f9;
    display: block; }

  .bensub-txt strong,
  .bensub-txt1 strong {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin: 0px 0;
    display: block;
    color: $white;
    display: block; }
   

// =============================================
//*** about us section - welcome to our company
// =============================================

.about-us {
  @include media-breakpoint-up(xs) {
    @include make-row();
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}

  // header 

  // header {
  //   @include media-breakpoint-up(xs) {
  //     @include make-col-ready();
  //     @include make-col(12);
  //     position: $relative;
  //     margin-bottom: $margin-10 * 3.5;
  //   }
  //   @include media-breakpoint-up(sm) {}
  //   @include media-breakpoint-up(md) {}
  //   @include media-breakpoint-up(lg) {
  //     @include make-row();
  //     margin-bottom: $margin-10 * 10;
  //   }
  //   @include media-breakpoint-up(xl) {}
  //   .left {
  //     @include media-breakpoint-up(xs) {
  //       @include make-col-ready();
        
  //     }
  //     @include media-breakpoint-up(sm) {}
  //     @include media-breakpoint-up(md) {}
  //     @include media-breakpoint-up(lg) {
  //       width: 30%;
  //     }
  //     @include media-breakpoint-up(xl) {
  //       width: 20%;
  //     }
  //   }
  //   .right {
  //     @include media-breakpoint-up(xs) {
  //       @include make-col(12);
  //     }
  //     @include media-breakpoint-up(sm) {}
  //     @include media-breakpoint-up(md) {}
  //     @include media-breakpoint-up(lg) {
  //       @include make-col-ready();
  //     }
  //     @include media-breakpoint-up(xl) {}
  //   }
  //   h2 {
  //     @include media-breakpoint-up(xs) {
  //       @include heading1( 40px, 40px, 15px, 0 0 0 0);
  //     }
  //     @include media-breakpoint-up(sm) {
  //       @include heading1( 50px, 50px, 15px, 0 0 0 0);
  //     }
  //     @include media-breakpoint-up(md) {
  //       @include heading1( 55px, 50px, 15px, 0 0 0 0);
  //     }
  //     @include media-breakpoint-up(lg) {
  //       @include heading1( 56px, 56px, 35px, 0 0 0 0);
       
  //     }
  //     span {
  //       @include media-breakpoint-up(xs) {
  //           @include heading1-span();
  //           letter-spacing: 1px;
  //           font-size: 12px;
  //           color: $nav-txt-color;
  //           margin-bottom: $margin-10 * 1.5;
  //       }
  //       @include media-breakpoint-up(sm) {
  //         @include heading1-span();
  //       }
  //       @include media-breakpoint-up(md) {
  //         @include heading1-span();
  //       }
  //       @include media-breakpoint-up(lg) {
  //         @include heading1-span();
  //       }
  //     }
  //   }
  //   p {
  //     @include media-breakpoint-up(xs) {
  //       width: 100%;
  //       padding-left: $padding-10 * 0;
  //       margin-bottom: 0px !important;
  //     }
  //     @include media-breakpoint-up(sm) {}
  //     @include media-breakpoint-up(md) {}
  //     @include media-breakpoint-up(lg) {}
      
  //   }
  // } 
  // end header

  section {
    @include media-breakpoint-up(xs) {
      @include make-col-ready();
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include make-col(7);
    }
    h2 {
      @include media-breakpoint-up(xs) {
        font-size: 28px;
        line-height: 30px;
        // text-transform: $capitalize-case;
        position: $relative;
        margin-bottom: $margin-10 * 2;
        font-weight: $font-weight-600;
        color: $heading-color;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {
        font-size: 44px;
        line-height: 44px;
        margin-bottom: $margin-10 * 3;
      }
    }
    p {
      @include media-breakpoint-up(xs) {}
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        @include make-col(10);
      }
    }
    p:nth-last-of-type(1) {
      @include media-breakpoint-up(xs) {
        margin-bottom: $margin-10 * 2.5;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        margin-bottom: $margin-10 * 4;
      }
      @include media-breakpoint-up(xl) {}
    }
    article,
    .abt-dtls {
      @include media-breakpoint-up(xs) {
        margin-top: $margin-10 * 0;
        margin-bottom: $margin-10 * 3;
      }
      @include media-breakpoint-up(sm) {
        margin-bottom: $margin-10 * 3;
      }
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        margin-bottom: $margin-10 * 6;
      }
     
    }
  }
  .swp-box {
    @include media-breakpoint-up(xs) {
      padding: $padding-5 * 5;
      box-shadow: -3.697px 52.871px 49px rgba(176,175,175,0.27);
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      padding: $padding-5 * 10;
    }
    @include media-breakpoint-up(lg) {
      padding: $padding-5 * 15;
    }
    @include media-breakpoint-up(xl) {}
    h3 {
      @include media-breakpoint-up(xs) {
        font-size: $peragraph-font-size + 4;
        line-height: $peragraph-font-size + 4;
        // text-transform: $capitalize-case;
        margin-bottom: $margin-10 * 3;
        font-weight: $font-weight-600;
        color: $heading-color;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
    }
  }
  .gym-list {
    @include media-breakpoint-up(xs) {
      margin-top: $margin-10 * 0;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      margin-top: $margin-10 * 3;
    }

    @include display-flex(flex, row, wrap, space-between, unset);
    margin-bottom:  $margin-10 * 0;
    li {
      @include media-breakpoint-up(xs) {
        @include make-col(6);
        margin: $margin-10 * 2 0;
      }
      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
      @include media-breakpoint-up(md) {
        @include make-col(4);
      }
      @include media-breakpoint-up(lg) {
        @include last(3){
          margin: $margin-10 * 2 0 0;
        }
      }
      @include display-flex(flex, row, wrap, unset, unset);
      a {
        @include media-breakpoint-up(xs) {
          display: inline-block;
          @include display-flex(flex, unset, unset, unset, center);
          font-family: $roboto;
          font-weight: $font-weight-500;
          font-size: $peragraph-font-size - 2;
          color: $base-color2;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        
        span {
          @include media-breakpoint-up(xs) {
            margin-right: $margin-10 * 1.5;
            display: inline-block;
            width: auto;
            max-width: 50px;
          }
          @include media-breakpoint-up(sm) {
            margin-right: $margin-10 * 2;
            width: auto;
            max-width: 100%;
          }
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {}
        } 
      }
    }
  }
  aside {
    @include media-breakpoint-up(xs) {
      @include make-col-ready();
      display: none;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      // @include make-col(4);
      @include make-col-offset(1);
      @include display-flex(flex, unset , unset, unset, flex-end )
    }
    figure {
      @include media-breakpoint-up(xs) {}
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        @include position($absolute, auto, 0, 50px, auto);
      }
      @include media-breakpoint-up(lg) {
       
      }
      @include media-breakpoint-up(xl) {}
    }
    
  }
}

.brand {
  @include media-breakpoint-up(xs) {
    display: none;
    background-color: $primary-color2;
    padding: $padding-10 * 4 0;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    @include display-flex(flex, row, nowrap, center, center);
  }
  @include media-breakpoint-up(xl) {}
}


// =============================================
//*** service row
// =============================================
.creation-sec {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}

  //  alternate box 
  ul.altr-box {
    @include media-breakpoint-up(xs) {
      background-color: $base-color10;
      padding-bottom: $padding-10 * 2;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      padding-bottom: $padding-10 * 3;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: $padding-10 * 0;
    }
    @include media-breakpoint-up(xl) {}
    li {
      @include media-breakpoint-up(xs) {
        background-color: $base-color10;
        position: $relative;
        overflow: hidden;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      &:nth-child(1){
        @include media-breakpoint-up(xs) {}
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
        
        .container {
          @include media-breakpoint-up(xs) {}
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {
           display: block;
          }
          @include media-breakpoint-up(lg) {
            @include display-flex( flex, row, unset, unset, center);
          }
          @include media-breakpoint-up(xl) {}
        }
        .left {
          @include media-breakpoint-up(xs) {
            padding: 30px 0;
            background-color: $base-color10;
          }
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {
            padding-left: 0px;
          }
          @include media-breakpoint-up(lg) {
            @include make-col-ready();
          }
          @include media-breakpoint-up(xl) {
            padding: 80px;
            padding-left: 0px;
          }
        }
        .right {
          figure {
            @include media-breakpoint-up(xs) {}
            @include media-breakpoint-up(sm) {}
            @include media-breakpoint-up(md) {}
            @include media-breakpoint-up(lg) {
              @include position( $absolute, 0, auto, 0, 30%);
              height: 100%;
              width: 100%;
              max-width: 80%;
              max-height: 100%;
              z-index: 0;
            }
            @include media-breakpoint-up(xl) {
              @include position( $absolute, 0, auto, 0, 50%);
              height: 100%;
              width: 100%;
              max-width: 100%;
              max-height: 100%;
              z-index: 0;
            }
          }
        }
      }
      &:nth-child(2) {
        @include media-breakpoint-up(xs) {}
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {
          padding-bottom: $padding-10 * 0;
        }
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
        .container {
          @include media-breakpoint-up(xs) {}
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {
            display: block;
          }
          @include media-breakpoint-up(lg) {
            @include display-flex( flex, row-reverse, unset, unset, center);
          }
          @include media-breakpoint-up(xl) {}
        }
        .left {
          @include media-breakpoint-up(xs) {
            padding-right: 0px;
            background-color: $base-color10;
          }
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {
            @include make-col-ready();
          }
          @include media-breakpoint-up(xl) {
            padding: 80px;
            padding-right: 0px;
          }
        }
        .right {
          figure {
            @include media-breakpoint-up(xs) {}
            @include media-breakpoint-up(sm) {}
            @include media-breakpoint-up(md) {}
            @include media-breakpoint-up(lg) {
              @include position( $absolute, 0, 0, 0, -20%);
              height: 100%;
              width: 100%;
              max-width: 100%;
              max-height: 100%;
              z-index: 0;
            }
            @include media-breakpoint-up(xl) {
              @include position( $absolute, 0, 0, 0, auto);
              height: 100%;
              width: 100%;
              max-width: 100%;
              max-height: 100%;
              z-index: 0;
            }
          }
        }
      }
    }
  }
}

ul.altr-box .left {
  @include media-breakpoint-up(xs) {
    @include make-col(12);
    padding-top: $padding-10 * 4;
    padding-bottom: $padding-10 * 4;
    position: $relative;
    padding-left: $padding-10 * 0;
    z-index: 1;
  } 
  @include media-breakpoint-up(sm) {
    padding: $padding-10 * 5;
    padding-left: $padding-10 * 0;
  }
  @include media-breakpoint-up(md) {
    @include make-col(12);
   
  }
  @include media-breakpoint-up(lg) {
    @include make-col(6);
    padding:  $padding-10 * 8;
    padding-left: $padding-10 * 5
  }
  @include media-breakpoint-up(xl) {
  }
  h2 {
    @include media-breakpoint-up(xs) {
      color: $white;
      font-weight: $font-weight-100; 
      font-size: 28px;
      line-height: 30px;
      font-weight: $font-weight-700;
      position: $relative;
      margin-bottom:  $margin-10 * 3;
      text-transform: capitalize;
      
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      font-size: 44px;
      line-height: 44px;
    }
  }
  p { 
    @include media-breakpoint-up(xs) {
      color: $txt-color1;
      line-height: 22px;
      margin-bottom: $margin-10 * 1.5;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      margin-bottom: $margin-10 * 3.5;
    }
    &:nth-last-of-type(1) {
      margin-bottom: $margin-10 * 4;
    }
  }
  a.btn-knmr {
    @include border-radius(40px);
    font-size: $small-font-size;
    text-transform: $upper-case;
    color: $btn-text;
    background-color: $btn-bg-hover;
    padding: $padding-10 ($padding-10 * 3);
    display: inline-block;
    font-weight: $font-weight-100;
  }
}
ul.altr-box .right {
  @include media-breakpoint-up(xs) {
    @include make-col(12);
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    @include make-col(6);
  }
  @include media-breakpoint-up(xl) {}
  figure {
    @include media-breakpoint-up(xs) {
      display: block;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
  }
}

// =========================================
//*** Acro event *** 
// =========================================

#page {
  @include media-breakpoint-up(xs) {
   
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(lg) {}
}
.fullwidth {
  max-width: 100%;
}
.event {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  header {
    @include media-breakpoint-up(xs) {}
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
  }
}
.slider-single {
  @include media-breakpoint-up(xs) {
    // margin-bottom: $margin-10 * 3;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    // margin-bottom: $margin-10 * 7;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: $margin-10 * 10;
  }
}
.slider-box {
  @include media-breakpoint-up(xs) {
    position: $relative;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    position: $relative;
    padding: $padding-10 * 5 0;
  }
  @include media-breakpoint-up(xl) {}
  
  .slider-cap {
    @include media-breakpoint-up(xs) {
      @include position( $absolute, 270px, auto, auto, 30px);
    }
    @include media-breakpoint-up(sm) {
      top: 49%;
    }
    @include media-breakpoint-up(md) {
      @include position( $absolute, 57%, auto, auto, 30px);
    }
    @include media-breakpoint-up(lg) {
      @include position( $absolute, 57%, auto, auto, 30px);
    }
    @include media-breakpoint-up(xl) {
      @include position(absolute, auto, auto, 90px, 100px);
    }
    strong {
      @include media-breakpoint-up(xs) {
        font-family: $roboto;
        font-size: $peragraph-font-size;
        color: $white;
        font-weight: $font-weight-600;
        display: block;
        margin-bottom: 5px;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        font-size: $peragraph-font-size + 2;
        line-height: $peragraph-font-size + 2;
      }
      @include media-breakpoint-up(xl) {}
    }
    span {
      @include media-breakpoint-up(xs) {
        font-family: $roboto;
        color: $white;
        font-size: $peragraph-font-size - 2;
        line-height: $peragraph-font-size - 2;
        font-weight: $font-weight-500;
       
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      
    }
  }
}
.slider-box.slick-slide {
  figure {
    background-color: #000;
  }
  img {
    @include media-breakpoint-up(xs) {}
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      width: 100%;
      max-width: 100%;
    }
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      width: auto;
    }
  }
}
.slider-nav {
 margin: 0 -15px;
}
.slider-nav .slick-slide {
  margin: 15px;
}
.slider-nav-item {
  @include media-breakpoint-up(xs) {
    @include display-flex (flex !important, row, nowrap, flex-start, center);
    background-color: #efefef;
    padding: $padding-10 * 1.5;
    cursor: pointer;
    box-shadow: 1px 0px 0px 0px #e0e0e0;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    @include display-flex (flex !important, row, nowrap, space-between, center);
  }
  @include media-breakpoint-up(lg) {
    @include display-flex (flex, row, nowrap, space-between, center);
    background-color: #efefef;
    padding: $padding-10 * 2.5 $padding-10 * 1.5;
    cursor: pointer;
  }
  @include media-breakpoint-up(xl) {}

  > span {
    @include media-breakpoint-up(xs) {
      display: inline-block;
      width:  auto;
      margin-right: $margin-10 * 1.8;
      float: left;
      border-radius: 50%;
      overflow: hidden;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      line-height: normal;
      float: none;
    }
    @include media-breakpoint-up(xl) {}
  }

  div {
    @include media-breakpoint-up(xs) {
      display: inline-block;
      width: 48%;
      padding: $padding-10 0;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    strong {
      @include media-breakpoint-up(xs) {
        font-size: $peragraph-font-size - 2;
        font-family: $Montserrat;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        font-size: $peragraph-font-size - 1;
        color: $heading-color;
        display: block;
      }
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
    }
    span {
      font-size: $small-font-size + 1;
      color: $heading-color;
      font-weight:  $font-weight-400;
      font-style: italic;
    }
  }
  &:focus , &:hover {
    @include transition(all 1s 0s);
    background-color: $base-color11;
    outline: none;
    strong, span {
      color: $white;
    }
  }
}

.event-dtls {
  @include media-breakpoint-up(xs) {
    @include position ( $relative, auto, auto, auto, auto);
    width: 100%;
    padding: $padding-10 * 3 $padding-10 * 1.5;
    background-color: $base-color10;
    height: 100%;
    z-index: 1; 
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    padding: $padding-10 * 10 $padding-10 * 5.5;
  }
  @include media-breakpoint-up(xl) {
    @include position ( $absolute, 0, 7%, auto, auto);
    width: 45%;
    padding: $padding-10 * 10 $padding-10 * 7;
  }
  h3 {
    @include media-breakpoint-up(xs) {
      font-size:  $small-font-size + 6;
      font-family: $open-sans;
      font-weight: $font-weight-700;
      color: $white;
      margin-bottom: $margin-10 * 2;
      padding-right: 0;
      // text-transform: $capitalize-case;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      padding-right: 60px;
    }
    @include media-breakpoint-up(lg) {
      font-size:  22px;
      margin-bottom: $margin-10 * 3.5;
    }
    @include media-breakpoint-up(xl) {
      padding-right: 100px;
    }
  }
  p {
    @include media-breakpoint-up(xs) {
      color: #99a7b5;
      margin-bottom: $margin-10 * 1.5;
      font-size:  $small-font-size + 2;
      font-weight: $font-weight-400;
      line-height: 22px;
      &:nth-of-type(2), &:nth-of-type(3) {
        display: none;
      }
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      &:nth-of-type(2) {
        display: block;
      }
      &:nth-of-type(3) {
        display: none;
      }
    }
    @include media-breakpoint-up(lg) {
      font-size: $peragraph-font-size;
      margin-bottom: $margin-10 * 2.8;
      &:nth-of-type(3) {
        display: block;
      }
    }
    @include media-breakpoint-up(xl) {}
  }
  .readmore {
    @include media-breakpoint-up(xs) {
      @include transition(all 1s ease 0s !important);
      display: inline-block;
      width: auto;
      margin-top: $margin-10 * 2;
      color: $white;
      font-size: 24px;
      overflow: hidden;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      font-size: 30px;
    }
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      font-size: 40px;
    }
  }
}

// =========================================
//*** subscribe-row  ***
// =========================================
#sebscribe-row {
  @include media-breakpoint-up(xs) {
    background: $base-color11;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  .sebscribe-box {
    @include media-breakpoint-up(xs) {
      @include display-flex(flex, column, unset, center, center);
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include display-flex(flex, column, unset, center, center);
    }
    h2 {
      @include media-breakpoint-up(xs) {
        color: $white;
        font-size: $small-font-size * 2;
        line-height: $small-font-size * 2;
        font-weight: $font-weight-600;
        font-family: $roboto;
        margin-bottom: $margin-10 * 2;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        font-size: $small-font-size * 3.5;
        line-height: $small-font-size * 3.5;
      }
    }
    span {
      @include media-breakpoint-up(xs) {
        font-size: 15px;
        font-weight: $font-weight-400;
        color: $white;
        text-align: center;
      }
      @include media-breakpoint-up(sm) {
        font-size: $peragraph-font-size;
      }
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        font-size: $peragraph-font-size + 2;
      }
      @include media-breakpoint-up(xl) {}
    }
    
    form {
      @include media-breakpoint-up(xs) {
        // @include make-col(11);
        margin-top: $margin-10 * 5;
        // height: 60px;
      }
      @include media-breakpoint-up(sm) {
        // @include make-col(8);
      }
      @include media-breakpoint-up(md) {
        margin-top: $margin-10 * 6;
        height: 60px;
      }
      @include media-breakpoint-up(lg) {
        margin-top: $margin-10 * 7.5;
        @include make-col(8);
      }
      @include media-breakpoint-up(xl) {
        margin-top: $margin-10 * 8.5;
      }
      input.input-subscribe {
        @include media-breakpoint-up(xs) {
          width: 100%;
          font-size: $peragraph-font-size - 2;
          margin-bottom: $margin-10 * 3;
          border-color: $white;
          color: $white;
          text-align: center;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
          width: 70%;
          text-align: left;
        }
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {
          width: 75%; 
        }
        &::-webkit-input-placeholder {
          @include media-breakpoint-up(xs) {
            @include placeholder ( $white, $peragraph-font-size - 2);
          }
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {}
          @include media-breakpoint-up(xl) {}
        }
        &::-moz-placeholder {
          @include media-breakpoint-up(xs) {
            @include placeholder ( $white, $peragraph-font-size - 2);
          }
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {}
          @include media-breakpoint-up(xl) {}
        }
        &:-ms-input-placeholder {
          @include media-breakpoint-up(xs) {
            @include placeholder ( $white, $peragraph-font-size - 2);
          }
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {}
          @include media-breakpoint-up(xl) {}
        }
        &:-moz-placeholder {
          @include media-breakpoint-up(xs) {
            @include placeholder ( $white, $peragraph-font-size - 2);
          }
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {}
          @include media-breakpoint-up(xl) {}
        }
      }
      .btn-submit {
        @include media-breakpoint-up(xs) {
          @include border-radius(30px);
          @include position(relative, auto, 0, 0, auto);
          @include align-self ( center );
          cursor: pointer;
          padding: $padding-10 * 1.7 $padding-10 * 6;
          font-size: $peragraph-font-size;
          color: $white;
          border-color: $white;
          background-color: $transparent;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {
          @include position($absolute, auto, 0, 0, auto);
          margin-top:  $margin-10 * 0;
        }
        @include media-breakpoint-up(lg) {}
        &:hover, &:focus {
          outline: none;
          color: $primary-color2 !important;
        }
        &:after, &:before {
          background-color: $white !important;
        }
      }
    }
  }
}

// =========================================
//*** provided services section
// =========================================

.provided-srvs {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    @include make-row();
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}

  header {
    @include media-breakpoint-up(xs) {
      @include make-col(12);
      @include make-col-ready();
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
  }
  section,
  .provided-srvs-dtls {
    @include media-breakpoint-up(xs) {
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      display: flex;
     
    }
    @include media-breakpoint-up(xl) {}
    .left {
      @include media-breakpoint-up(xs) {}
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        @include make-col-ready();
      }
      @include media-breakpoint-up(lg) {
        padding: 0;
        @include make-col(7);
        @include display-flex (flex, column, unset, unset , unset);
       
      }
      @include media-breakpoint-up(xl) {
        @include display-flex (flex, unset, unset, unset , unset);
        @include make-col(8);
      }
      article {
        @include media-breakpoint-up(xs) {
          margin-bottom: 30px;
          counter-increment: section;
          position: $relative;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          margin-bottom: 30px;
          // @include make-col(12);
          @include make-col-ready();
        }
        @include media-breakpoint-up(xl) {
          margin-bottom:  0px;
          @include make-col(6);
        }

        > div {
          @include media-breakpoint-up(xs) {
            overflow: hidden;
            background-color: #f7f7f7;
            padding: $padding-10 * 3 !important; 
          }
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {
            padding: $padding-10 * 4 !important; 
          }
          @include media-breakpoint-up(lg) {}
          @include media-breakpoint-up(xl) {
            padding: $padding-10 * 4 $padding-10 * 2 $padding-10 * 3.3 $padding-10 * 7 !important; 
          }
          
        }
        // &:before {
        //   @include media-breakpoint-up(xs) {
        //     content: counter(section);
        //     @include position($absolute, 20px, 0, auto, -15px);
        //     font-family: $open-sans;
        //     font-size: $small-font-size * 2;
        //     color: $primary-color2;
        //     font-weight: $font-weight-700;
        //     text-align: center;
        //     width: $padding-10 * 8.5 !important;
        //   }
        //   @include media-breakpoint-up(sm) {}
        //   @include media-breakpoint-up(md) {
        //     @include position($absolute, 40px, 0, auto, 0);
        //   }
        //   @include media-breakpoint-up(lg) {}
        //   @include media-breakpoint-up(xl) {}
        // }
        
        h3 {
          @include media-breakpoint-up(xs) {
            position: $relative;
            font-size: $small-font-size * 1.8;
            font-weight: $font-weight-700;
            color: $heading-color;
            margin-bottom: $margin-10;
            padding-bottom:  $padding-10 * 1.5;
          }
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {
            margin-bottom: $margin-10 * 2;
          }
          @include media-breakpoint-up(lg) {}
          @include media-breakpoint-up(xl) {
            margin-bottom: $margin-10 * 3;
          }
          // &:after {
          //   @include media-breakpoint-up(xs) {
          //     content: "";
          //     background-color: $primary-color;
          //     @include position(absolute, auto, auto, 0, 0);
          //     @include equal-box(40px, 1px);
          //   }
          //   @include media-breakpoint-up(sm) {}
          //   @include media-breakpoint-up(md) {}
          //   @include media-breakpoint-up(lg) {}
          //   @include media-breakpoint-up(xl) {}
          // }
          i {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            width: 60px;
            background-color: #e9e9e9;
            border-radius: 50%;
            float: left;
            font-size: 38px;
            color: $primary-color2;
            margin-right: 20px;
          }
        }
        p {
          @include media-breakpoint-up(sm) {
            color: $nav-txt-color;
            margin-bottom:  $margin-10 * 3.4;
            line-height: 27px;
          }
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {}
          @include media-breakpoint-up(xl) {}
          @include media-breakpoint-up(xl) {}
        }
        .btn-km {
          padding: $padding-5 * 3 $padding-5 * 7; 
        }
        &:nth-last-of-type(1) {
          @include media-breakpoint-up(xs) {}
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {
            margin-bottom: 0px;
          }
          @include media-breakpoint-up(xl) {}
        }
      }
    }
    .right {
      @include media-breakpoint-up(xs) {
        // @include display-flex( flex, unset, unset, flex-start, flex-end);
        display: -webkit-box;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        @include make-col-ready();
      }
      @include media-breakpoint-up(lg) {
        @include make-col(5);
      }
      @include media-breakpoint-up(xl) {
        @include make-col(4);
      }
      img {
        @include media-breakpoint-up(xs) {
          display: inline-block;
          width: auto;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
      figure {
        @include media-breakpoint-up(xs) {
          background-color: #ebebeb;
          text-align: right;
          width: 100%;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {
          @include display-flex( flex, row, unset, flex-end, flex-end);
        }
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {
          padding: 0px;
        }
      }
    }
  }
}


// =========================================
// *** Team Section
// =========================================

.team-sec {
  @include media-breakpoint-up(xs) {
    background-color: $base-color1;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  
  header {
    @include media-breakpoint-up(xs) {}
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
  }
  .team {
    @include media-breakpoint-up(xs) {}
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
  }
  .customNavigation {
    @include display-flex(flex, row, nowrap, center, center);
  }
  .owl-controls {
    .owl-buttons {
      .owl-prev,
      .owl-next {
        background: none;
        opacity: 1;
        font-size: $peragraph-font-size * 2;
        i {
          color: #101010;
        }
      }
    }
  }

}

.tm-box {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  
  .tm-social {
    @include position ($absolute, 10px, 0, auto, 0);
    z-index: 0;
    @include transition(z-index 1s 0s, color 0s);
    ul {
      @include display-flex(flex, row, nowrap, center, center);
      width: 100%;
      padding: $padding-10;
      li {
        @include display-flex(flex, unset, unset, unset, unset);
        a {
          padding: $padding-10 * 1.5 $padding-10;
          color: $base-color11;
          font-size: 22px; 
          &:hover {
            color: $primary-color2;
          }
        }
      }
    }
  }
  .tm-body {
    @include media-breakpoint-up(xs) {
      cursor: pointer;
      @include position ($relative, 0, 0, 0, 0);
      @include transition(all 1s 0s, color 0s);
      z-index: 1;
      padding-bottom: $padding-10 * 7;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      padding-bottom: $padding-10 * 6;
    }
    @include media-breakpoint-up(xl) {}
    img {
      width:  100%;
    }
    .viewdetails {
      @include transition(all 1s 0s);
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 100%;
      right: 0;
      margin: auto;
      width: 60px;
      height: 60px;
      background-color: $white;
      border-radius: 50%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      opacity: 0;
      img {
        display: inline-block;
        width: auto;
      }
    }
  }
  .tm-footer {
    @include position ($absolute, auto, 0, 20px, 0);
    z-index: 0;
    text-align: center;
    strong{
      display: block;
      color:  #282828;
      font-family: $open-sans;
      font-size: $peragraph-font-size + 2;
      font-weight: $font-weight-600;
      line-height: normal;
      font-style: initial;
    }
    span {
      display: block;
      color:  #595959;
      font-family: $open-sans;
      font-size: 14px;
      font-style: italic; 
      font-weight: $font-weight-400;
      line-height: normal;
    }
  }

  &:hover {
    .tm-social {
      z-index: 9; 
      @include transition(z-index 1s .5s, color 0s);
    }
    .tm-body {
      padding-bottom: $padding-10 * 0;
      padding-top: $padding-10 * 7;
      // z-index: 0; 
      figure {
        background-color: $primary-color2;
        height: 435px;
        img {
          opacity: .5;
        }
        
      }
      .viewdetails {
        bottom: -10%;
        opacity: 1;
      }
    }
    .tm-footer {
      position: $absolute;
      // bottom: 20px;
      // z-index: -1; 
    }
  }
}

.owl-carousel {
  @include media-breakpoint-up(xs) {
    margin-bottom: 0 !important;
    margin-bottom: $margin-10 * 3;
    min-height: 540px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
  }
  .owl-item {
    @include media-breakpoint-up(xs) {
      padding: 0;
    }
    @include media-breakpoint-up(sm) {
      padding: $padding-5 * 3;
    }
    @include media-breakpoint-up(md) {
      padding: $padding-5 * 3;
    }
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
  }
  
}

.team-wraper {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    margin-left: -15px;
    margin-right: -15px;
  }
}
#owl-demo {
  .owl-wrapper {
    min-height: 540px;
  }
}


// =========================================
//*** gallery 
// =========================================

.gallery {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  header {
    @include media-breakpoint-up(xs) {}
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
  }
}

// =========================================
//*** product-sec
// =========================================

.product-sec {
  background-color: $base-color1;

  header {}

  .item {
    padding: $padding-10 * 2;
    background-color: $white;
    cursor: pointer;
    @include transition(box-shadow 1s 0s);
    // box-shadow: 0 14px 38px 10px #ddd;
    figure {
      @include media-breakpoint-up(xs) {
        @include display-flex(flex, column, nowrap, center, center);
        margin-bottom: $margin-10;
        min-height: 0;
      }
      @include media-breakpoint-up(sm) {
        min-height: 260px;
      }
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
    }
    figcaption,
    .item-dtls {
      @include media-breakpoint-up(xs) {}
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}

      @include display-flex(flex, row, nowrap, space-between, flex-start);
      padding: $padding-10 0 0 0;
      position: $relative;
      .left {
        @include display-flex(flex, column, nowrap, left, unset);
        text-align: left;
        width: 80%;
        strong, span {
          font-family: $roboto;
          font-weight: $font-weight-500;
          color: $heading-color;
          font-size: $peragraph-font-size;
          margin-bottom: $margin-10; 
        }
        span {
          margin-bottom: 0px;
          font-weight: $font-weight-500;
        }
      }
      .right {
        .cart-item {
          @include border-radius(50%);
          background-color: $base-color11;
          display: inline-block;
          width: auto;
          padding: 15px;
          font-size: 22px;
          line-height: 22px;
          color: $white;
        }
        img {
          @include position($absolute, auto, 15px, 20px, auto);
          &:nth-of-type(2){ 
            display: none;
          }
        }
      }
    }
    &:hover {
      box-shadow: 0 14px 38px 10px #ddd;
      .item-dtls {
        .cart-item {
          background-color: $primary-color2;
        }
      }
    }
  }
}

#owl-demo1.owl-carousel {
  min-height: 0px;
  .owl-item {
    @include media-breakpoint-up(xs) {
      padding-top: 0px;
      padding-bottom: 40px;
      padding-left: 0px;
      padding-right: 0px;
    }
    @include media-breakpoint-up(sm) {
      padding-left: $padding-5 * 2;
      padding-right: $padding-5 * 2;
    }
    @include media-breakpoint-up(md) {
      padding-left: $padding-5 * 3;
      padding-right: $padding-5 * 3;
    }
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}

   
  }
  .owl-controls {
    .owl-page {
      span {
        height: 8px;
        width: 8px;
        margin: 5px;
      }
    }
    .owl-page.active {
      span {
        height: 12px;
        width: 12px;
      }
    }
  }
}

// =========================================
//*** contact-row
// =========================================

.contact-area {
  @include media-breakpoint-up(xs) {
    background: $white;
    // background: linear-gradient(to bottom, $white 60%, #262f39 0%);
    position: $relative;
    z-index: 0;
  }
  @include media-breakpoint-up(sm) {
    // background: linear-gradient(to bottom, $white 58%, #262f39 0%);
  }
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    // background: linear-gradient(to right, $white 50%, #262f39 0%);
  }
  @include media-breakpoint-up(xl) {}
  .container {
    position: relative;
    z-index: 3;
  }
 
}
.contact-row {
  @include make-row();
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    @include justify-content(space-between);
  }
  @include media-breakpoint-up(xl) {}
  .contacts {
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      padding-bottom: $padding-10 * 0;
      margin-bottom: $margin-10 * 3;
    }
    @include media-breakpoint-up(lg) {
      @include make-col(6);
      margin-bottom: $margin-10 * 0;
    }
    @include media-breakpoint-up(xl) {}
    @include make-col-ready();
    @include make-col(12);
    position: $relative;
    z-index: 0;
    padding-bottom: $padding-10 * 5;
    

    > ul {
      li {
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:before {
        @include media-breakpoint-up(xs) {
          @include position ($absolute, auto, auto, 0, 0);
          content: "";
          background-color: #707070;
          height: 1px;
          width: 100%;
          margin: auto;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          width: 65%;
        }
        @include media-breakpoint-up(xl) {}
      }
      &:after {
        @include media-breakpoint-up(xs) {
          @include position ($absolute, auto, auto, 0, 0);
          content: "";
          background-color: #ddd;
          height: 1px;
          width: 100%;
          margin: auto;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          width: 65%;
        }
        @include media-breakpoint-up(xl) {}
      }
    }
    header {
      @include media-breakpoint-up(xs) {}
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      p {
        @include media-breakpoint-up(xs) {}
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          @include make-col(12);
        }
        @include media-breakpoint-up(xl) {
          @include make-col(12);
        }
      }
    }
  }
  .contact-form {
    @include media-breakpoint-up(xs) {
      @include make-col-ready();
      @include make-col(12);
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }
    @include media-breakpoint-up(xl) {}
    form {
      @include media-breakpoint-up(xs) {
        @include align-item(flex-end);
        box-shadow: 0px 55px 71px 0 rgba(176, 176, 176, 0.3);
        // -4.534px 64.842px 70px rgba(176,176,176,0.49);
        padding: $padding-5 * 5;
      }
      @include media-breakpoint-up(sm) {
        padding: $padding-5 * 7;
      }
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        padding: 60px;
      }
      @include media-breakpoint-up(xl) {}
      input {
        @include media-breakpoint-up(xs) {
          font-size: $peragraph-font-size + 2;
          border-color: rgba(0, 0, 0, .2);
          color: $heading-color;
          margin-bottom: $margin-10 * 3;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {
          margin-bottom: $margin-10 * 4;
        }
        @include media-breakpoint-up(lg) {
          margin-bottom: $margin-10 * 5;
        }
        @include media-breakpoint-up(xl) {
          margin-bottom: $margin-10 * 6;
        }
        &::-webkit-input-placeholder {
          @include placeholder ( $heading-color, 16px);
          font-weight: $font-weight-400;
        }
        &::-moz-placeholder {
          @include placeholder ( $heading-color, 16px);
          font-weight: $font-weight-400;
        }
        &:-ms-input-placeholder {
          @include placeholder ( $heading-color, 16px);
          font-weight: $font-weight-400;
        }
        &:-moz-placeholder {
          @include placeholder ( $heading-color, 16px);
          font-weight: $font-weight-400;
        }
      }
    }
  }
}

.social {
  h4 {
    font-size: $peragraph-font-size - 2;
    text-transform: $upper-case;
    font-weight: $font-weight-600;
    margin-bottom: $margin-10 * 3;
  }
  ul {
    margin-bottom: 0px;
    padding: 0px;
    li {
      margin-bottom: 0;
      margin-right: $margin-10 * 1.5;
      a {
        @include border-radius(50%);
        border: 2px solid $base-color11;
        color: $base-color11;
        font-size: $peragraph-font-size;
        line-height: $peragraph-font-size;
        padding: 8px;
      }
    }
  }
}

