// 
.well {
  @include media-breakpoint-up(xs) {
    margin-bottom: $margin-10 * 2.5;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    margin-bottom: $margin-10 * 6;
  }
  @include media-breakpoint-up(xl) {}
  .btn-group.filters-button-group {
    @include display-flex(flex, row, wrap, flex-start, center);
    background-color: transparent !important;
  }
}

.btn-group {
  @include display-flex(flex, row, wrap, flex-start, center);
  @include media-breakpoint-up(xs) {
    .btn-sort {
      @include media-breakpoint-up(xs) {
        @include border-radius(0);
        @include media-breakpoint-up(xs) {
        @include transition(padding 1s 0s);
        background-color: $white !important;
        color: $text-color-light;
        font-family: $roboto;
        font-size: $peragraph-font-size - 1;
        line-height: $peragraph-font-size + 4;
        font-weight: $font-weight-700;
        padding: 0 15px 0 0;
        margin: 0 15px 0 0;
        text-transform: $capitalize-case;
        margin-bottom: $margin-10 * 2;
        border-right: 1px solid #e1e1e1;
        position: $relative;
        z-index: 1;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        font-size: $peragraph-font-size + 2;
        padding: 0 12px 0 0;
        margin: 0 12px 0 0;
      }
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}


      
        &:after {
          @include border-radius(50%);
          @include position($absolute, 0, auto, 0, 0);
          content: "";
          background-color: $base-color11;
          height: 7px;
          width: 7px;
          margin: auto;
          z-index: -1;
        }
        
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        margin-bottom: $margin-10 * 0;
      }
      @include media-breakpoint-up(xl) {}
      cursor: pointer;
      &:hover, &:focus {
        @include transition(padding 1s 0s);
        @include box-shadow(0, 0, 0, 0, #fff, inset);
        color: $base-color11;;
        outline: none !important;
        padding-left: $padding-10 * 1.8;
        z-index: 1;
      }
      span {
        display: inline-block;
        width: auto;
        background-color: $white !important;
      }
      &:nth-last-of-type(1){
        border-right: none;

      }
      // &:nth-of-type(3){
      //   @include media-breakpoint-up(xs) {
      //     margin-right: 0;
      //   }
      //   @include media-breakpoint-up(sm) {
      //     margin-right: 15%;
      //   }
      //   @include media-breakpoint-up(md) {
      //     margin-right: 0;
      //   }
      //   @include media-breakpoint-up(lg) {}
      //   @include media-breakpoint-up(xl) {}
      // }
    }
    .btn-sort.active {
      color: $base-color11;
      background-color: $white !important;
      padding-left: $padding-10 * 1.8;
      z-index: 1;
    }
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
}
.col-media {
  @include media-breakpoint-up(sm) {
    max-width: 49.5%;
  }
  @include media-breakpoint-up(md) {
    max-width: 33.33%;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    // max-width: 100%;
  }
  .thumbnail {
    @include media-breakpoint-up(xs) {}
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      max-width: 330px;
    }
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      max-width: 390px;
    }
  }
}
.box-height1,
.box-height2 {
  @include media-breakpoint-up(xs) {
    // max-height: 370px;
    overflow: hidden;
  }
  @include media-breakpoint-up(sm) {
    // max-height: 250px;
  }
  @include media-breakpoint-up(md) {
    // max-height: 300px;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    max-height: 370px;
  }
  
}
