// =========================================
//*** banner
// =========================================
.inner-banner.service {
  @include media-breakpoint-up(xs) {
    background-image: none;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
    min-height: 160px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    min-height: 350px;
  }
  @include media-breakpoint-up(lg) {
    
  }
  @include media-breakpoint-up(xl) {
    background-image: url(#{$img-path}/banner-services.jpg);
    min-height: 400px;
  }
  img {
    @include media-breakpoint-up(xs) {
      display: block;
      position: $absolute;
      height: 100%;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  .container {
    @include position ($absolute, 0, 0, 0, 0);
    margin: auto;
    .caption {
      @include media-breakpoint-up(xs) {
        @include display-flex(flex, unset, nowrap, unset, center);
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      h1 {
        @include media-breakpoint-up(xs) {
          color: #8b8b8b;
       }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
    }
  }
}


// =========================================
//*** acro services row
// =========================================

.service-row {

  // header 
  header {
    @include media-breakpoint-up(xs) {
      margin-bottom: $margin-10 * 6;
    }
    @include media-breakpoint-up(sm) {
      margin-bottom: $margin-10 * 9;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: $margin-10 * 12;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: $margin-10 * 15;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: $margin-10 * 18;
    }
  }
}

  // service details 1
  .service-dtls1 {
    @include media-breakpoint-up(xs) {
      @include make-col(12);
      margin-bottom: $margin-10 * 6;
    }
    @include media-breakpoint-up(sm) {
      margin-bottom: $margin-10 * 8;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: $margin-10 * 10;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: $margin-10 * 12;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: $margin-10 * 14;
    }
    h3 {
      @include media-breakpoint-up(xs) {
        font-size: $small-font-size * 2;
        font-weight: $font-weight-700;
        color: $heading-color;
        text-transform: $capitalize-case;
        margin-bottom: $margin-10 * 2;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {
        margin-bottom: $margin-10 * 3;
      }
    }
    p {
      text-transform: $capitalize-case;
      margin-bottom: $margin-10 * 3;
    }
  }
  
  // service details 2
  .service-dtls2 {
    @include media-breakpoint-up(xs) {
      display: flex;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      margin-bottom: $margin-10 * 6;
    }
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    .service-img {
      @include media-breakpoint-up(xs) {
        display: none;
        position: relative;
        z-index: 0;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        display: block;
      }
      @include media-breakpoint-up(lg) {
        @include display-flex (flex, column, nowrap, flex-end, flex-end);
      }
      @include media-breakpoint-up(xl) {
        @include make-col(6);
      }
      figure {
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
      &:after {
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {
          content: "&";
          position: absolute;
          font-size: 500px;
          line-height: 610px;
          font-family: $Montserrat;
          color: #f1f1f1;
          font-weight: $font-weight-600;
          z-index: -1;
          top: auto;
          bottom: 0;
        }
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {
          font-size: 700px;
        }
      }
    }
  }

  // service list
  ul.service-list {
    @include media-breakpoint-up(xs) {
      position: $relative;
      z-index: 1;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      @include make-col(6);
      box-shadow: 4.881px 55.787px 68px rgba(193,192,191,0.43);
      padding: 45px;
      margin-bottom: $margin-10 * 6;
    }
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      padding: 85px;
    }
    li {
      @include media-breakpoint-up(xs) {
        margin-bottom: $margin-10 * 2;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        display: block;
        width: 100%;
        margin-bottom: $margin-10 * 3.5;
      }
      @include media-breakpoint-up(xl) {
        display: block;
        width: 100%;
      }
      &:last-child() {
        margin-bottom: 0;
      }
    }
  }

a.icon-box {
  @include media-breakpoint-up(xs) {
    @include display-flex (flex, row, nowrap, flex-start, center);
    font-size: $peragraph-font-size;
    font-weight: $font-weight-600;
    color: $base-color6;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
   
  }
  @include media-breakpoint-up(xl) {}
  span {
    @include media-breakpoint-up(xs) {
      @include equal-box (70px, 70px);
      display: inline-block;
      position: $relative;
      margin-right: $margin-10;
      background-color: #e9e8e8;
      border-radius: 50%;
      padding: 15px;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    img {
      // @include position($absolute, 0, 0, 0, 0);
     
    }
  }
}
a.icon-box.active {
  span {
    img {
     
    }
  }
}

// =========================================
//*** author-row 
// =========================================

.author-row {
  @include media-breakpoint-up(xs) {
    background-color: $primary-color2;
    padding: $padding-10 * 4 0;
    text-align: center;
    background-attachment: fixed;
  }
  @include media-breakpoint-up(sm) {
    padding: $padding-10 * 6 0;
  }
  @include media-breakpoint-up(md) {
    padding: $padding-10 * 9 0;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    padding: $padding-10 * 10 0;
  }
  p {
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    color: $white;
    line-height: 22px;
    margin-bottom: $margin-10 * 3;
    font-style: italic;
  }
  span {
    font-size: 16px;
    line-height: 16px;
    text-transform: $upper-case;
    font-weight: $font-weight-700;
    font-family: $roboto;
    color: $white;
    display: inline-block;
    margin-bottom: $margin-10 * 3;
  }
}
#author-slider {
  min-height: 0;
  .owl-item {
    padding: 0px !important;
    text-align: center;
    .item {
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {
        padding: 0 18%;
      }
     
    }
  }
 
}

#author-slider.owl-theme {
  .owl-controls{
    .owl-page {
      span {
        display: block;
        width: 8px;
        height: 8px;
        margin: 5px 7px;
        opacity: 0.5;
        border-radius: 20px;
        background: $white;
      }
    } 
    .owl-page.active {
      span {
        opacity: 1;
      }
    }
  } 
}



// =========================================
//*** certificate-row
// =========================================

// .certificate-row {
//   @include media-breakpoint-up(xs) {
//     padding: $padding-10 * 4 0;
//   }
//   @include media-breakpoint-up(sm) {
//     padding: $padding-10 * 6 0;
//   }
//   @include media-breakpoint-up(md) {
//     @include make-row ();
//     padding: $padding-10 * 8 0;
//     @include align-item (center);
//   }
//   @include media-breakpoint-up(lg) {
//     @include make-row ();
//     padding: $padding-10 * 10 0;
//   }
//   @include media-breakpoint-up(xl) {}
//   .left {
//     @include media-breakpoint-up(xs) {
//       @include make-col-ready ();
//     }
//     @include media-breakpoint-up(sm) {}
//     @include media-breakpoint-up(md) {
//       @include make-col (5);
//     }
//     @include media-breakpoint-up(lg) {
//       @include make-col (5);
//     }
//     @include media-breakpoint-up(xl) {
//       @include make-col (6);
//     }

//     figure {
//       @include media-breakpoint-up(xs) {
//         display: inline-block;
//         position: $relative;
//         padding-top: 0px;
        
//       }
//       @include media-breakpoint-up(sm) {}
//       @include media-breakpoint-up(md) {}
//       @include media-breakpoint-up(lg) {}
//       @include media-breakpoint-up(xl) {
//         padding: 0px $padding-10 * 5 $padding-10;
        
        
//       }

//       &:after {
//         @include media-breakpoint-up(xs) {
//           @include position(absolute, auto, 0, -60px, 0);
//           @include equal-box(100%, 60px);
//           content: "";
//           background: url(#{$img-path}/certificate-shadow.png) no-repeat center center / 100% 100%;
//           margin: auto;
//         }
//         @include media-breakpoint-up(sm) {}
//         @include media-breakpoint-up(md) {}
//         @include media-breakpoint-up(lg) {}
//         @include media-breakpoint-up(xl) {}

       
//       }
//     }
//   }

//   .right {
//     @include media-breakpoint-up(xs) {
//       @include make-col-ready ();
//     }
//     @include media-breakpoint-up(sm) {}
//     @include media-breakpoint-up(md) {
//       @include make-col (7);
//     }
//     @include media-breakpoint-up(lg) {
//       @include make-col (7);
//     }
//     @include media-breakpoint-up(xl) {
//       @include make-col (6);
//     }
//     .header {
//       position: $relative;
//       h2 {
//         @include media-breakpoint-up(xs) {
//           @include heading1( 30px, 30px, 25px, 35px 0 0 15px);
//           font-weight: $font-weight-400 !important;
//           font-family: $roboto;
//           color: #4c4a4a !important;
//         }
//         @include media-breakpoint-up(sm) {}
//         @include media-breakpoint-up(md) {}
//         @include media-breakpoint-up(lg) {
//           @include heading1( 75px, 75px, 40px, 45px 0 0 35px);
//         }
//         @include media-breakpoint-up(xl) {
//           @include heading1( 75px, 75px, 40px, 45px 0 0 35px);
//         }
//         span {
//           @include media-breakpoint-up(xs) {
//             font-size: $small-font-size + 2;
//             font-weight: $font-weight-300;
//             letter-spacing: 1px;
//             font-family: $roboto;
//             line-height: normal;
//             display: block;
//             color: #4c4a4a;
//           }
//           @include media-breakpoint-up(sm) {}
//           @include media-breakpoint-up(md) {}
//           @include media-breakpoint-up(lg) {}
//           @include media-breakpoint-up(xl) {
//             font-size: $small-font-size * 4;
//           }
//         }
//       } 
//       &:after {
//         @include media-breakpoint-up(xs) {
//           content: "";
//           background-color: $primary-color ;
//           @include position(absolute,auto, 0, 0, 0);
//           @include equal-box(3px, 100%)
//         }
//         @include media-breakpoint-up(sm) {}
//         @include media-breakpoint-up(md) {
//           @include equal-box(5px, 100%);
//           @include position(absolute,auto, 0, 0, 0);
//         }
//         @include media-breakpoint-up(lg) {
//           @include equal-box(7px, 100%);
//         }
//       }
//     }
    

//     p {
//       @include media-breakpoint-up(xs) {
//         font-weight: $font-weight-400;
//         line-height: 22px;
//         margin-bottom: $margin-10 * 2.5;
//       }
//       @include media-breakpoint-up(sm) {
//         margin-bottom: $margin-10 * 3;
//       }
//       @include media-breakpoint-up(md) {
//         margin-bottom: $margin-10 * 3.5;
//       }
//       @include media-breakpoint-up(lg) {
//         margin-bottom: $margin-10 * 4.5;
//       }
//       @include media-breakpoint-up(xl) {
//         margin-bottom: $margin-10 * 5.5;
//       }
//     }
    
//   }
// }

.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    width: 280px;
    
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    width: 415px;
    flex-direction: row;
  }
  box-shadow: 1.239px 26.989px 98px rgba(137,137,137,0.28);
  visibility: hidden;
  width: 290px;
  background-color: #fff;
  color: #fff;
  text-align: left;
  padding: 30px;
  position: absolute;
  z-index: 1;
  bottom: auto;
  top: 0px;
  left: 0;
  margin-left: -30px;
  display: flex;
  flex-direction: column;
  z-index: 99;
  span {
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    display: inline-block;
    margin-right: 25px;
    margin-bottom: 15px;
  }
  div {
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      width: 70%;
    }
    width: 100%;
    display: inline-block;
    position: relative;
    strong {
      font-family: $roboto;
      font-size: 16px;
      color: $heading-color;
      font-weight: $font-weight-600;
      margin-bottom: 30px;
      font-style: normal;
      display: inline-block;
    }
    p {
      font-size: 14px;
    }
  }
}

/* Popup arrow */
// .popup .popuptext::after {
//   content: "";
//   position: absolute;
//   top: 100%;
//   left: 50%;
//   margin-left: -5px;
//   border-width: 5px;
//   border-style: solid;
//   border-color: #555 transparent transparent transparent;
// }



/* Toggle this class - hide and show the popup */
.popup .show,
.popup.show .popuptext {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}


/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;} 
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}

span.close {
  display: inline-block !important;
  background-color: #353535 !important;
  border-radius: 50px !important;
  height: 30px !important;
  width: 30px !important;
  color: #fff !important;
  position: absolute !important;
  right: 15px !important;
  top: 15px !important;
  font-weight: $font-weight-300 !important;
  font-family: $roboto !important;
  margin: 0px !important;
  font-size: 15px !important;
  line-height: 10px !important;
  padding: 10px !important;
  text-align: center !important;
}