// =========================================
//*** Sidebar area
// =========================================

.sidebar {
  @include media-breakpoint-up(xs) {
    @include make-col-ready();
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(3);
  }
  > div {
    @include media-breakpoint-up(xs) {
      margin-bottom: $margin-10 * 3;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      margin-bottom: $margin-10 * 5;
    }
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      margin-bottom: $margin-10 * 8;
    }
  }
  h3 {
    @include media-breakpoint-up(xs) {
      font-size: 20px;
      margin-bottom: $margin-10 * 1.7;
      font-weight: $font-weight-600;
      color: $heading-color;
      text-transform: $capitalize-case;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
  }
}

// =========================================
//*** blog-search 
// =========================================

.input-search {
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid;
  border-color: #bcbcbc;
  color: $base-color7;
  font-weight: $font-weight-400;
  padding: $padding-10  $padding-10 * 3  $padding-10  0;
  width: 100%;
  font-size: $peragraph-font-size - 2;

  &::-webkit-input-placeholder {
    @include placeholder ($base-color9, 14px);
    font-weight: $font-weight-400;
  }
  &::-moz-placeholder {
    @include placeholder ($base-color9, 14px);
    font-weight: $font-weight-400;
  }
  &:-ms-input-placeholder {
    @include placeholder ($base-color9, 14px);
    font-weight: $font-weight-400;
  }
  &:-moz-placeholder {
    @include placeholder ($base-color9, 14px);
    font-weight: $font-weight-400;
  }
}

// =========================================
//*** Related Events 
// =========================================
.event-list {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  li {
    @include media-breakpoint-up(xs) {
      padding-bottom: $padding-5 * 3;
      margin-bottom: $margin-10 * 1.5;
      border-bottom: 1px solid #bcbcbc;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    a {
      @include media-breakpoint-up(xs) {
        @include display-flex(flex, row, nowrap, unset, unset);
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      figure {
        @include media-breakpoint-up(xs) {
          max-width: 100px;
          width: auto;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          max-width: 100px;
          width: 100px;
        }
        @include media-breakpoint-up(xl) {}
      }
      figcaption,
      .evnt-dtls {
        @include media-breakpoint-up(xs) {
          padding-left: $padding-5 * 5;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
        p {
          font-size: $small-font-size + 1;
          line-height: 20px; 
          margin-bottom: $margin-10 - 5;
          font-weight: $font-weight-300;
        }
        .event-date {
          color: $btn-bg;
          font-family: $roboto;
          font-size: $small-font-size;
          font-weight: $font-weight-300;
          display: block;
        }
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

// =========================================
//*** Related Events 2
// =========================================
.event-list2 {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  li {
    @include media-breakpoint-up(xs) {
      padding-bottom: $padding-5 * 3;
      margin-bottom: $margin-10 * 1.5;
      // border-bottom: 1px solid #bcbcbc;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    a {
      @include media-breakpoint-up(xs) {
        @include display-flex(flex, row, nowrap, flex-start, center);
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      figure {
        @include media-breakpoint-up(xs) {
          max-width: 100px;
          border-radius: 50%;
          overflow: hidden;
          border: 3px solid $white;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          width: 100px;
        }
        @include media-breakpoint-up(xl) {}
        img {
          display: block;
        } 

      }
      figcaption,
      .evnt-dtls {
        @include media-breakpoint-up(xs) {
          padding-left: $padding-5 * 5;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
        p {
          font-size: $small-font-size + 1;
          line-height: 20px; 
          margin-bottom: $margin-10 - 5;
          font-weight: $font-weight-400;
        }
        .event-date {
          color: #878787;
          font-family: $roboto;
          font-size: $small-font-size;
          font-weight: $font-weight-400;
          display: block;
        }
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

// =========================================
//*** popular-courses
// =========================================

.popular-courses {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
}
.courses-list {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  li {
    @include media-breakpoint-up(xs) {
      border-bottom: 1px solid #bcbcbc;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    a {
      @include media-breakpoint-up(xs) {
        display: block;
        padding: $padding-5 * 3; 
        color: $base-color4;
        font-size: $peragraph-font-size;
        &:hover, &:focus {
          background-color: $btn-bg;
          color: $base-color1;
        }
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        padding: $padding-5 * 4 $padding-5 * 3; 
      }
      @include media-breakpoint-up(lg) {
        padding: $padding-5 * 5 $padding-5 * 3; 
      }
      @include media-breakpoint-up(xl) {}
    }
    &:first-child {
      border-top: 1px solid #bcbcbc;
    }
  }
  li.active {
    border-color: $btn-bg;
    a {
      background-color: $btn-bg;
      color: $base-color1;
      }
  }
}

// =========================================
//*** popular-courses2
// =========================================

.popular-courses2 {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
}
.courses-list2 {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  li {
    @include media-breakpoint-up(xs) {
      position: relative;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    a {
      @include media-breakpoint-up(xs) {
        display: block;
        padding: $padding-5 * 3 $padding-5 * 3 $padding-5 * 3 $padding-5 * 5; 
        color: $base-color4;
        font-size: $peragraph-font-size;
        // &:hover, &:focus {
        //   color: $primary-color2;
        // }
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
    }
    &:before {
      @include position($absolute, 0, auto, 0, 0 );
      content: "";
      background-color: #959595;
      height: 6px;
      width: 6px;
      margin: auto;
      border-radius: 15px;
    }
    &:hover, &:focus {
      &:before { background-color: $primary-color2; }
    }
  }
  li.active {
    &:before {  background-color: $primary-color2; }
  }
}

// =========================================
//*** keyword Search | Product Search
// =========================================
.key-search {
  h3 {
    // margin-bottom: $margin-10 * 0;
  }
}

.input-search2 {
  border: none;
  border-bottom: 1px solid #bcbcbc;
  color: $base-color7;
  font-weight: $font-weight-500;
  width: 100%;
  font-size: $peragraph-font-size - 2;
  background: url(#{$img-path}/search-icon.png) no-repeat 100% center;
  text-transform: $capitalize-case;
  padding: 10px 0;

  &::-webkit-input-placeholder {
    @include placeholder ($base-color9, 14px);
    font-weight: $font-weight-400;
  }
  &::-moz-placeholder {
    @include placeholder ($base-color9, 14px);
    font-weight: $font-weight-400;
  }
  &:-ms-input-placeholder {
    @include placeholder ($base-color9, 14px);
    font-weight: $font-weight-400;
  }
  &:-moz-placeholder {
    @include placeholder ($base-color9, 14px);
    font-weight: $font-weight-400;
  }
}

// =========================================
//*** Category
// =========================================

.category-box {
  .styled-select {
    width: 100%;
  }
}

// =========================================
//*** All Products
// =========================================

.all-products {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
}
.all-products-list {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  li {
    @include media-breakpoint-up(xs) {
    
      border-bottom: 1px solid #bcbcbc;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    a {
      @include media-breakpoint-up(xs) {
        @include display-flex (flex, row, nowrap, space-between, center);
        // display: block;
        padding: $padding-5 * 3 0; 
        color: $base-color4;
        font-size: $peragraph-font-size;
        @include transition(padding 1s ease, color 0s ease);
        &:hover, &:focus {
          background-color: $btn-bg;
          color: $base-color1;
        }
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        padding: $padding-5 * 4 0; 
      }
      @include media-breakpoint-up(lg) {
        padding: $padding-5 * 5 0; 
      }
      @include media-breakpoint-up(xl) {}
      &:hover {
        @include media-breakpoint-up(xs) {
          padding: $padding-5 * 3; 
        }
        @include media-breakpoint-up(sm) {
          padding: $padding-5 * 4 $padding-5 * 3; 
        }
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          padding: $padding-5 * 5 $padding-5 * 3; 
        }
        @include media-breakpoint-up(xl) {}
      }
    }
    &:first-child {
      border-top: 1px solid #bcbcbc;
    }
  }
  li.active {
    border-color: $btn-bg;
    a {
      @include media-breakpoint-up(xs) {
        background-color: $btn-bg;
        color: $base-color1;
        padding: $padding-5 * 3; 
      }
      @include media-breakpoint-up(sm) {
        padding: $padding-5 * 4 $padding-5 * 3; 
      }
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        padding: $padding-5 * 5 $padding-5 * 3; 
      }
      @include media-breakpoint-up(xl) {}
      }
  }
}


// =========================================
//*** Products List
// =========================================

.product-list {
  .form-check {
    margin-bottom:  $margin-10 * 2;
    padding-left: $padding-5 * 5;
    label {
      
    }
    .form-check-input {
      margin-top: 2px;
      margin-left: $margin-10 * -2.5;
    }
  }
  .radios {
    label {
      font-size: $peragraph-font-size - 2;
      font-weight: $font-weight-400;
    }
  }
  
}

.input-replace {
  @include equal-box(17px, 17px);
  // background: #c2c2c2;
  border: 1px solid #c2c2c2;
  float: left;
  margin-right: 10px;
  border-radius: 50%;
  position: relative;
  top: 0;
  cursor: pointer;

  &:after {
    @include position ($absolute, -1px, auto, auto, -1px);
    @include equal-box(17px, 17px);
    @include border-radius(50%);
    content: '';
    background: $primary-color2;
    transform: scale(0);
    @include transition(all 0.1s);
    visible: hidden;
  }
  &.checked:after {
      transform: scale(1);
      visible: visible;
  }
}
.checks .input-replace:after {
  background: #e74c3c;
}
label {
  font-size: $peragraph-font-size + 2;
  color: $base-color4;
  font-family: $roboto;
  font-weight: $font-weight-300;
  display: inline-block;
  height: auto;
  cursor: pointer;
  margin-bottom: 20px; 
  text-transform: $capitalize-case;
}


// =========================================
//*** color List
// =========================================

.color-list {
  .form-check {
    margin-bottom:  $margin-10 * 2;
    padding-left: $padding-5 * 5;
    label {}
    .form-check-input {
      margin-top: 2px;
      margin-left: $margin-10 * -2.5;
    }
  }
  .radios {
    label {
      font-size: $peragraph-font-size - 2;
      font-weight: $font-weight-400;
      margin-bottom: 0px;
      color: $heading-color !important;
    }
  }
  .radios > div { 
    display: inline-block; 
    &:nth-of-type(1) {
      .input-replace { 
        background-color: #2ecc71; 
        border-color: #2ecc71;
        &:after {
          background-color: transparent; 
          border: 1px solid #2ecc71; 
        }
      } 
    }
    &:nth-of-type(2) {
      .input-replace { 
        background-color: #e73a72; 
        border-color: #e73a72;
        &:after {
          background-color: transparent; 
          border: 1px solid #e73a72; 
        }
      } 
    }
    &:nth-of-type(3) {
      .input-replace { 
        background-color: #cbab85; 
        border-color: #cbab85;
        &:after {
          background-color: transparent; 
          border: 1px solid #cbab85; 
        }
      } 
    }
    &:nth-of-type(4) {
      .input-replace { 
        background-color: #363636; 
        border-color: #363636;
        &:after {
          background-color: transparent; 
          border: 1px solid #363636;  
        }
      } 
    }
  }


  .input-replace {
    @include equal-box(22px, 22px);
    // background: #c2c2c2;
    border: 1px solid #c2c2c2;
    float: left;
    margin-right: 10px;
    border-radius: 50%;
    position: relative;
    top: 0;
    cursor: pointer;

    &:after {
      @include position ($absolute, -5px, auto, auto, -5px);
      @include equal-box(28px, 28px);
      @include border-radius(50%);
      content: '';
      background: $btn-bg;
      transform: scale(0);
      @include transition(all 0.1s);
      visibility: hidden;
    }
    &.checked:after {
      transform: scale(1);
      visibility: visible;
    }
  }
  .checks .input-replace:after {
    // background: #e74c3c;
  }
  label {
    font-size: $peragraph-font-size + 2;
    color: $base-color4;
    font-family: $roboto;
    font-weight: $font-weight-300;
    display: inline-block;
    height: auto;
    cursor: pointer;
    margin-bottom: $margin-10 * 2;
    text-transform: $capitalize-case;
  }
}

// =========================================
//*** calender 
// =========================================

.pignose-calendar {
  width: 100%;
  border: none;
  border-bottom: 1px solid #f7f7f7;
  box-shadow: 0px 20px 60px rgba(188,211,217,0.35);
  background-color: $transparent;
  .pignose-calendar-top  {
    @include box-shadow ( 0, 0, 0, 0, #fff);
    padding: $padding-10 * 3 0 $padding-10 * 1.8 0;
    background-color: $white;
    border: none;
    .pignose-calendar-top-year {
      // display: none !important;
    }
    .pignose-calendar-top-nav.pignose-calendar-top-prev {
      @include position($absolute, 20px, auto, auto, 0px);
      span {
        color: $heading-color !important;
      }
    }
    .pignose-calendar-top-nav.pignose-calendar-top-next {
      @include position($absolute, 20px, 0px, auto, auto );
      span {
        color: $heading-color !important;
      }
    }
    .pignose-calendar-top-date {
      padding: $padding-10 *1.8 0;
      .pignose-calendar-top-month {
        color: $heading-color !important;
        font-weight: $font-weight-500 !important;
        font-family: $roboto !important;
        text-transform: $capitalize-case;
        font-size: $peragraph-font-size;
      }
      
    }
  }

  .pignose-calendar-header {
    background-color: #f9f9f9;
    margin-top: 0 !important;
    padding-bottom: 0!important;
    padding-left: $padding-10 ;
    padding-right: $padding-10 * .8;
    .pignose-calendar-week {
      color: $heading-color !important;
      font-weight: $font-weight-400 !important;
      font-family: $roboto !important;
    }
  }

  .pignose-calendar-body {
    padding: $padding-10 !important;
    background-color: $white;
    .pignose-calendar-unit {
      height: 45px;
      a {
        color: #9e9e9e !important;
      }
    }
    .pignose-calendar-unit-active  {
      a {
        color: $white !important;
        background-color: $primary-color2;
        width: 2em !important;
        height: 2em !important;
        line-height: 2em !important;
      }
    }
  }
} //pignose-calendar 

// =========================================  
//*** price-range-slider
// =========================================
.price-filter {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
  form {
    @include media-breakpoint-up(xs) {
      @include display-flex(flex, row, nowrap, space-between, flex-end);
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
  }
}
.range-slider {
  width: 60%;
  text-align: center;
  position: relative;
  height: 0;
  top: -13px;
  span {
    display: inline-block;
    width: 100%;
    text-align: left;
    text-transform: capitalize;
    color: $base-color9;
    font-size: $peragraph-font-size - 2;
  }
}
.range-slider svg,
.range-slider input[type=range] {
  position: absolute;
  left: 0;
  bottom: 0;
}
input[type=number] {
  border: 1px solid #ddd;
  background-color: transparent!important;
  text-align: center;
  font-size: $peragraph-font-size - 2;
  font-weight: $font-weight-400;
  border: none;
  -moz-appearance: textfield;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type=number]:invalid,
input[type=number]:out-of-range {
  // border: 1px solid #ff6347;
}
input[type=range] {
  -webkit-appearance: none;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #7d7d7d;
}
input[type=range]:focus::-ms-fill-lower {
  background: #7d7d7d;
}
input[type=range]:focus::-ms-fill-upper {
  background: #7d7d7d;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  animate: 0.2s;
  background: #7d7d7d;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}
input[type=range]::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px $base-color6;
  border: 1px solid #7d7d7d;
  height: 10px;
  width: 10px;
  border-radius: 25px;
  background: #7d7d7d;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4px;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: $primary-color2;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}
input[type=range]::-moz-range-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px $base-color6;
  border: 1px solid $primary-color2;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #7d7d7d;;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower,
input[type=range]::-ms-fill-upper {
  background: #7d7d7d;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}
input[type=range]::-ms-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #7d7d7d;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #7d7d7d;
  cursor: pointer;
}
.btn-fltr {
  display: inline-block;
  width: 80px;
  padding: 7px !important;
  font-size: $small-font-size !important;;
  font-weight: $font-weight-400 !important;;
}

// =========================================  
//*** video-box
// =========================================
.video-box {
  position: relative;
  video {
    max-width: 100%;
    width: 100%;
  }
}
#atlanticlight {
  position: relative;
  video {
    width: 100%;
    height: auto;
    border: 5px solid $white;
  }
  button {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    svg {
      width: 30px;
      margin: 0 auto;
      fill: #fff;
      transition: .6s opacity;  
    }
  }
  #playpause { display: none; }
  .playing { opacity: 0; }
}


// =========================================  
//*** ads
// =========================================
.ads {
  display: inline-block;
  img {
   border: 5px solid $white; 
  }
}