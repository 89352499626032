// ====================================
// path map
// ====================================
$img-path: '../images';
$slick-font-path: "../fonts/";
$slick-loader-path: "../";
$fa-font-path: "../fonts";
$icomoon-font-path: "../fonts";



// none -value
$none: none;

// ====================================
// color var
// ====================================
$white: #fff !default;
$black: #000;

$base-color1: #f6f7f7;
$base-color2: #2d3844;
$base-color3: #060809;
$base-color4: #6a6a6a;
$base-color5: #3f3f3f;
$base-color6: #585858;
$base-color7: #4c4a4a;
$base-color8: #919191;
$base-color9: #858484;
$base-color10: #1e2a37;
$base-color11: #289fb4;

$join-color: #b7489d;
$subscribe-txt-color: #7d9eb9;
$txt-color1: #a7b6c2;

// *** Primary Colors ***
$primary-color: #ee5c8d !default;
$primary-color2: #48b1c3;

//button1
$btn-bg: #b3486c;
$btn-text: $white;
$btn-bg-hover: $primary-color;
$btn-text-hover: $white;

// button2
$btn-bg1: #2c3b47;
$btn-text1: #8597a6;
$btn-bg1-hover: $base-color3;
$btn-text1-hover: $white;

// button3 
$btn-text2: #b7489d;
$btn-border: #b7489d;

$btn-bg2-hover: $btn-border;
$btn-text2-hover: $white;

// typography
$heading-color: #353535 !default;
$text-color-light: #6a6a6a !default;
$text-color-dark: #373737;
$text-color-hover: $primary-color;

// Nav Colors 
$nav-txt-color: #323232;
$nav-txt-color-hover: #b73e67;
$transparent: rgba(0, 0, 0, 0);

// ====================================
// position
// ====================================
$relative: relative;
$absolute: absolute;

// ====================================
// alignment
// ====================================

$text-align-center: center;
$margin-auto: auto;

// ====================================
// fonts 
// ====================================

// size
$small-font-size: 12px;
$peragraph-font-size: 16px;
$h1-heading: $small-font-size * 5; 

// case
$upper-case: uppercase;
$capitalize-case: capitalize;

// weight
$font-weight-100: 100;
$font-weight-200: 200;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;
$font-weight-900: 900;

// ====================================
// others var
// ====================================

// display 
$display-block: block;
$text-decoration-none: none;

//padding
$padding-5: 5px;
$padding-10: 10px;

//margin
$margin-10: 10px;

