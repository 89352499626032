// =========================================
//*** banner
// =========================================
.inner-banner.contact {
  @include media-breakpoint-up(xs) {
    background-image: none;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
    min-height: 160px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    min-height: 280px;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    background-image: url(#{$img-path}/banner-contact.jpg);
    min-height: 400px;
  }
   img {
    @include media-breakpoint-up(xs) {
      display: block;
      position: $absolute;
      height: 100%;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  .container {
      @include position ($absolute, 0, 0, 0, 0);
      margin: auto;
      .caption {
        @include media-breakpoint-up(xs) {
          @include display-flex(flex, unset, nowrap, unset, center);
          @include position ($absolute, 0, 0, 0, 0);
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      h1 {
        @include media-breakpoint-up(xs) {
          color: #535353;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
    }
  }
}


// =========================================
//*** get in touch with us 
// =========================================

.contact-section {
  header {
    @include media-breakpoint-up(xs) {
      margin-bottom: $margin-10 * 6;
    }
    @include media-breakpoint-up(sm) {
      margin-bottom: $margin-10 * 9;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: $margin-10 * 12;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: $margin-10 * 15;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: $margin-10 * 18;
    }
  }
}

.contact-body {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    @include make-row();
  }
  @include media-breakpoint-up(xl) {}
  .left {
    @include media-breakpoint-up(xs) {
      margin-bottom: $margin-10 * 3;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include make-col(5);
      @include make-col-ready();
    }
    @include media-breakpoint-up(xl) {}
  }
  .right {
    @include media-breakpoint-up(xs) {}
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include make-col(6);
      @include make-col-ready();
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(xl) {}
  }
  form {
    @include align-item (flex-end);
    input {
      @include media-breakpoint-up(xs) {
        margin-bottom: $margin-10 * 2;
        color: $heading-color;
        font-size: $peragraph-font-size - 2;
        border-color: $heading-color;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        margin-bottom: $margin-10 * 2.5;
      }
      @include media-breakpoint-up(xl) {
        margin-bottom: $margin-10 * 3;
      }
      &:nth-of-type(3) {
        @include media-breakpoint-up(xs) {
          margin-bottom: $margin-10 * 4;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
      &::-webkit-input-placeholder {
        @include media-breakpoint-up(xs) {
          @include placeholder ( $heading-color, 14px );
          font-weight:  $font-weight-400;
          font-family: $roboto;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          @include placeholder (  $heading-color, 14px);
        }
        @include media-breakpoint-up(xl) {}
      }
      &::-moz-placeholder {
        @include media-breakpoint-up(xs) {
          @include placeholder (  $heading-color, 14px);
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          @include placeholder (  $heading-color, 14px);
        }
        @include media-breakpoint-up(xl) {}
      }
      &:-ms-input-placeholder {
        @include media-breakpoint-up(xs) {
          @include placeholder (  $base-color5, 14px);
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          @include placeholder (  $base-color5, 14px);
        }
        @include media-breakpoint-up(xl) {}
      }
      &:-moz-placeholder {
        @include media-breakpoint-up(xs) {
          @include placeholder (  $base-color5, 14px);
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          @include placeholder (  $base-color5, 14px);
        }
        @include media-breakpoint-up(xl) {}
      }
      &:focus {
        box-shadow: none;
        outline: none;
        background-position: 0 0;
        &::-webkit-input-placeholder {
          color: $heading-color;
          font-size: 11px;
          transform: translateY(-20px);
          visibility: visible !important;
        }
      }
    }
    .btn-submit2 {
      margin-top: 0px;
    }
  }
}
.contact-info {
  @include media-breakpoint-up(xs) {
    padding-bottom: $padding-10 * 2;
    margin-bottom: $margin-10 * 2;
    border-bottom: 1px solid #e8e8e8;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    margin-bottom: $margin-10 * 3;
  }
  @include media-breakpoint-up(xl) {}
  ul {
    padding-bottom: $padding-10 * 0;
    margin-bottom: $margin-10 * 2;
    li {
      @include media-breakpoint-up(xs) {
        margin-bottom: $margin-10 * 2;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        margin-bottom: $margin-10 * 2;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: $margin-10 * 2.5;
      }
      @include media-breakpoint-up(xl) {
        margin-bottom: $margin-10 * 3;
      }

    }
  }
}