// =========================================
//*** banner
// =========================================
.inner-banner.coach-banner {
  @include media-breakpoint-up(xs) {
    background-image: none;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
    min-height: 160px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    min-height: 350px;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    background-image: url(#{$img-path}/banner-coaches.jpg);
    min-height: 400px;
  }

  img {
    @include media-breakpoint-up(xs) {
      display: block;
      position: $absolute;
      height: 100%;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  .container {
    @include position ($absolute, 0, 0, 0, 0);
    margin: auto;
    .caption {
      @include media-breakpoint-up(xs) {
        @include display-flex(flex, unset, nowrap, unset, center);
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      h1 {
        @include media-breakpoint-up(xs) {
          color: #535353;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
    }
  }
}

// =========================================
//*** coaches 
// =========================================

.team-page {
  @include media-breakpoint-up(xs) {
    @include justify-content (center);
  }
  @include media-breakpoint-up(sm) {
    @include make-row();
  }
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    @include make-row();
  }
  @include media-breakpoint-up(xl) {}
  
  .tm-box {
    @include media-breakpoint-up(xs) {
      position: $relative;
      margin-bottom:  $margin-10 * 2;
      @include last(1) {
        margin-bottom: 0px;
      }
    }
    @include media-breakpoint-up(sm) {
      @include make-col(6);
      @include make-col-ready();
    }
    @include media-breakpoint-up(md) {
      @include make-col(4);
      min-height: 425px;
      @include last(2) {
        margin-bottom: 0px;
      }
    }
    @include media-breakpoint-up(lg) {
      @include make-col(3);
      margin-bottom:  $margin-10 * 3;
      min-height: 430px;
    }
    @include media-breakpoint-up(xl) {
      min-height: 510px;
      @include last(4) {
        margin-bottom: 0px;
      }
    }
    
    .tm-social {
      @include position ($absolute, 0, 0, auto, 0);
      ul {
        padding: 0;
      }
    }
    .tm-body {
      cursor: pointer !important;
    }
    .tm-footer {
      @include position ($absolute, auto, 0, 10px, 0);
    }

  }
}

//modal box
.tmdt-modal {
  @include media-breakpoint-up(xs) {
    box-shadow: 0px 55px 84px rgba(10,10,10,0.27);
    border-radius: 0;
    border: none;
    padding:  $padding-10 * 3;
    text-align: center;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    text-align: left;
    padding:  $padding-10 * 6;
  }
  @include media-breakpoint-up(xl) {}

  .modal-header {
    border: none;
    padding: 0px;
    .close {
      @include position( $absolute, 50px, 50px, auto, auto);
      color: #080808;
      z-index: 9;
      opacity: 1;
    }
  }
  .modal-wraper {
    @include media-breakpoint-up(xs) {}
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include make-row();
    }
    @include media-breakpoint-up(xl) {}
    figure {
      @include media-breakpoint-up(xs) {
        display: inline-block;
        @include border-radius(50%);
        width: 175px;
        height: 175px;
        overflow: hidden;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        @include border-radius(50%);
        width: 175px;
        height: 175px;
        overflow: hidden;
      }
      @include media-breakpoint-up(xl) {}
    }
    
    .tm-dtls {
      @include media-breakpoint-up(xs) {
        padding: $padding-10 * 2;
        width: 100%;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        padding-left: $padding-10 * 6;
        width: 75%;
      }
      @include media-breakpoint-up(xl) {}
      
      h3 {
        @include media-breakpoint-up(xs) {
          font-size:  $peragraph-font-size + 6;
          line-height: $peragraph-font-size + 6;
          font-weight: $font-weight-600;
          color: $nav-txt-color;
          margin-bottom: $margin-10 * 1.2;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
      span {
        @include media-breakpoint-up(xs) {
          font-size:  $peragraph-font-size;
          font-weight: $font-weight-400;
          font-style: italic;
          color: #595959;
          margin-bottom: $margin-10 * 2.8;
          display: block;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
      p {
        @include media-breakpoint-up(xs) {
          font-size:  $peragraph-font-size - 2;
          font-weight: $font-weight-400;
          margin-bottom: $margin-10 * 1.8;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
      strong {
        @include media-breakpoint-up(xs) {
          font-size:  $peragraph-font-size + 2;
          font-weight: $font-weight-600;
          margin-bottom: $margin-10 * 3;
          display: block;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
      .tmdtl-social {
        @include media-breakpoint-up(xs) {
          @include display-flex(flex, row, nowrap, center, center);
          padding-top: $padding-10 * 3;
          border-top: 1px solid #ebebeb;
         
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          @include display-flex(flex, row, nowrap, flex-start, center);
        }
        @include media-breakpoint-up(xl) {}
         li {
          @include media-breakpoint-up(xs) {
            margin-right: $margin-10 * 2;
          }
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {}
          @include media-breakpoint-up(xl) {}
           a {
            @include media-breakpoint-up(xs) {
              font-size:  $peragraph-font-size;
              font-weight: $font-weight-600;
              margin-bottom: $margin-10 * 3;
              color: $primary-color2;
            }
            @include media-breakpoint-up(sm) {}
            @include media-breakpoint-up(md) {}
            @include media-breakpoint-up(lg) {}
            @include media-breakpoint-up(xl) {}
           }
         }
        
      }
    }
  }
}
