// =========================================
//*** banner
// =========================================
.inner-banner.about {
  @include media-breakpoint-up(xs) {
    background-image: none;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
    min-height: 160px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {
    min-height: 350px;
  }
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {
    background-image: url(#{$img-path}/banner-about.jpg);
    min-height: 400px;
  }

  img {
    @include media-breakpoint-up(xs) {
      display: block;
      position: $absolute;
      height: 100%;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  .container {
    @include position ($absolute, 0, 0, 0, 0);
    margin: auto;
    .caption {
      @include media-breakpoint-up(xs) {
        @include display-flex(flex, unset, nowrap, unset, center);
        // @include position ($absolute, 0, 0, 0, 0);
        text-align: right;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {}
      @include media-breakpoint-up(xl) {}
      h1 {
        @include media-breakpoint-up(xs) {
          color: #535353;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
      &:after {
        @include media-breakpoint-up(xs) {}
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {}
        @include media-breakpoint-up(xl) {}
      }
    }
  }
}


// =========================================
//*** get in touch with us 
// =========================================

.abt-sec-1 {
  @include media-breakpoint-up(xs) {
    // background-color: #f6f6f6;
    // padding: $padding-10 * 3;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    @include make-row();
    // padding: $padding-10 * 5;
  }
  @include media-breakpoint-up(xl) {}
  .left {
    @include media-breakpoint-up(xs) {
      margin-bottom: 30px;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include make-col-ready();
      @include make-col(6);
    }
    @include media-breakpoint-up(xl) {}
    figure {
      img {
        border: 11px solid #fff;
        box-shadow: 0px 48px 87px rgba(146,146,146,0.54);
      }
    }
  }

  .right {
    @include media-breakpoint-up(xs) {}
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      @include make-col-ready();
      @include make-col(6);
    }
    @include media-breakpoint-up(xl) {}
  }
}
.right {
  h3 {
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
    }
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
    font-size: $small-font-size * 2;
    line-height: $small-font-size * 2;
    font-weight: $font-weight-700; 
    text-transform: $capitalize-case;
    margin-bottom:  $margin-10 * 3;
    color:  $heading-color;
    border-left: 2px solid $primary-color2;
    padding-left: $padding-10;
  }
  p {
    @include media-breakpoint-up(xs) {}
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {
      margin-bottom:  $margin-10 * 3;
      line-height: 24px;
    }
    @include media-breakpoint-up(xl) {}

    &:nth-last-of-type(1) {
      @include media-breakpoint-up(xs) {}
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {}
      @include media-breakpoint-up(lg) {
        margin-bottom:  $margin-10 * 3;
      }
      @include media-breakpoint-up(xl) {}
    }
  }
}
strong {
  font-size: 18px;
  font-style: italic;
  font-weight: $font-weight-500;
}
.sign {
  @include media-breakpoint-up(xs) {
    display: inline-block;
    padding-right: $padding-10 * 4.5;
    width: 180px;
  }
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    float: right;
  }
  @include media-breakpoint-up(xl) {}
  
  img {
    margin-bottom: $margin-10;
  }
  span {
    font-family: $roboto;
    font-style: italic;
    font-weight: $font-weight-300;
    font-size: $peragraph-font-size;
    color: $heading-color;
  }
}
// =========================================
//*** progress Section | progress-row  ***
// =========================================

#progress-row {
  @include media-breakpoint-up(xs) {}
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}

  // background: #1a2129 url(#{$img-path}/progress-back.jpg) no-repeat center center;
  background-color: $primary-color2;
  background-attachment: fixed;
  background-size: cover;
  .progress-box {
    @include media-breakpoint-up(xs) {
      @include display-flex(flex, column, unset, center, center);
      width: 100%;
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
      @include display-flex(flex, row, wrap, center, center);
    }
    @include media-breakpoint-up(lg) {
      @include display-flex(flex, row, unset, center, center);
      width: auto;
    }
    .circle-box {
      @include media-breakpoint-up(xs) {
        margin-bottom: $margin-10 * 3;
      }
      @include media-breakpoint-up(sm) {}
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(3);
        text-align: center;
        margin-bottom: $margin-10 * 0;
      }
      @include media-breakpoint-up(xl) {}
      div {
        @include media-breakpoint-up(xs) {
          @include display-flex(flex, column, unset, center, center);
          position: relative;
          margin-bottom: $margin-10 * 1.5;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          margin-bottom: $margin-10 * 3;
        }
        @include media-breakpoint-up(xl) {}
        strong {
          @include media-breakpoint-up(xs) {
            @include position($absolute, 0, 0, 0, 0);
            font-family: $roboto;
            font-size: $small-font-size * 3;
            line-height: $small-font-size * 3;
            height: $small-font-size * 3;
            margin: auto;
            color: $white;
            font-weight: $font-weight-400;
            text-align: center;
            font-style: initial;
            i{
              font-family: $roboto;
            }
          }
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {}
          @include media-breakpoint-up(lg) {
            font-size: $small-font-size * 3.4;
            line-height: $small-font-size * 3.4;
            height: $small-font-size * 3.4;
          }
          @include media-breakpoint-up(xl) {}
        }
        canvas {
          @include media-breakpoint-up(xs) {
            width: 160px !important;
            height: 160px !important;
          }
          @include media-breakpoint-up(sm) {}
          @include media-breakpoint-up(md) {
            width: 220px !important;
            height: 220px !important;
          }
          @include media-breakpoint-up(lg) {
            width: inherit;
            height: inherit;
          }
          @include media-breakpoint-up(xl) {}
          
        }
      }
      .pb-cap {
        @include media-breakpoint-up(xs) {
          font-family: $roboto;
          font-size: $peragraph-font-size;
          color: $white;
          font-weight: $font-weight-500;
          // letter-spacing: 1px;
          display: block;
          text-align: center;
        }
        @include media-breakpoint-up(sm) {}
        @include media-breakpoint-up(md) {}
        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }
        @include media-breakpoint-up(xl) {}
      }
    }
  }
}
