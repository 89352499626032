// *** only for header sass ***

#top-header,
.top-header {
  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}

  background-color: $white;
  .container {
    @include media-breakpoint-down(sm) {}
  }
}

